import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Redirect } from 'react-router';

export default function UnauthorizedToken(props) {
    const [open, setOpen] = React.useState(false);
    const [ReturnLogin, setReturnLogin] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        localStorage.setItem("key-refresh-token", "");
        localStorage.setItem("key-token","");
        localStorage.clear();
        setReturnLogin(true);
    };

    if(ReturnLogin){
        return <Redirect to="/"/>
    }

    return (
    
    <Dialog
        open={props.open}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title22">{"¡Alerta!"}</DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
            {"Su sesión a caducado."}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleClose} color="primary" autoFocus>
                Ok
            </Button>
        </DialogActions>
    </Dialog>
  );
}