import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import OptionView from './OptionView';
//import './Style.css';

const useStyles = makeStyles((theme) => ({
    root: {
        /*display: 'flex',*/
    },    
    toolbar: {
      /*display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(0, 1),
      // necessary for content to be below app bar
      ...theme.mixins.toolbar,*/
    },
    content: {
      /*flexGrow: 1,
      padding: theme.spacing(3),*/
    },
  }));

export default function Main (props){
    const classes = useStyles();
    return(
        <div className={classes.root}>
           <main className={classes.content}>
                <div className={classes.toolbar} />
                <OptionView param={props.param}/>
            </main>
        </div>        
    );
}