import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';  
import ShowDialog from '../dialogs/ShowDialog';
import UnauthorizedToken from '../dialogs/UnauthorizedToken';
import YearDatePicker from '../date/YearDatePicker.js';
import {MainContext} from '../../context/MainAppProvider';
import ActivationsCode from '../dialogs/ActivationsCode';
import Progress from '../progress/Progress';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
  });

export default function Activate (props){
    const classes = useStyles();    
    const [param, setParam] = React.useState(4);
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [ListUser, setListUser] = React.useState([]);
    const [IdEdit, setIdEdit] = React.useState('');
    const [NameCall, setNameCall] = React.useState('');
    const [UserCodeDelete, setUserCodeDelete] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [StatusDelete, setStatusDelete] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [Name, setName] = React.useState('-');
    const [UserType, setUserType] = React.useState(0);
    const [ListCourses, setListCourses] = React.useState([]);
    const [ListGrades, setListGrades] = React.useState([]);
    const [ListActivate, setListActivate] = React.useState([]);
    const [ListBook, setListBook] = React.useState([]);
    const [CourseId, setCourseId] = React.useState(0);
    const [GradeId, setGradeId] = React.useState(0);
    const [BookId, setBookId] = React.useState(0);
    const [Title, setTitle] = React.useState('-');
    const [CodeLibroDelete, setCodeLibroDelete] = React.useState('');
    const [Activates, setActivates] = React.useState(false);
    const [ValidToken, setValidToken] = React.useState(false);
    const {getDateLocal,getYearToday,getMonthToday}  = React.useContext(MainContext);
    const [dateInit, setDateInit] = React.useState(new Date(getDateLocal()));
    const [year, setYear] = React.useState(getYearToday());
    const [OpenActivationsCode, setOpenActivationsCode] = React.useState(false);

    //----loader----
    const [loading, setLoading] = React.useState(false);
    

    React.useEffect(()=>{
        GetListGrade();
        GetListCourse();
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleClose = () => {
        setShow(false);       
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    function handleClick() {
      setNewRegister(true);
    }

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    const handleFilterTitle = (e) =>{
      if(e.target.value.toString() == "")
      {
        setTitle('-');
      }
      else{
        setTitle(e.target.value.toString());
      }
    }
    const handleChangeUserType = (event) =>{
      setUserType(event.target.value);
    }

    const handleChangeCourse = (event) => {
      setCourseId(event.target.value);
    }

    const handleChangeGrade = (event) => {
      setGradeId(event.target.value);
    }

    const handleConsult = (event) =>{
        //if (GradeId > 0) {
        GetListCodigos();   
        //}
        //else{
        //    ShowDialogMessage('Debe escoger un grado.');
        //}
    }

    function handleExport(){
      GetDowlandActivate();
    }

    const handleActivations = () => {
      setOpenActivationsCode(true);
    };

    const handleChangeActivate = (event) => {
        if (Activates) {
            setActivates(false);
        }
        else{
            setActivates(true);
        }
    }

    const GetListGrade = () =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_GRADE = process.env.REACT_APP_API_GET_GRADE;
        let URL = URL_BASE+GET_GRADE;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        setLoading(true)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
              if(res.status === 401){
                return 401;  
              }
                return res.json();
            }).then(function(response){
                setLoading(false)
                if(response.Code === 0){
                    setListGrades(response.Data)
    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
                else if(response === 401){
                  setValidToken(true);
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }
    
    const GetListCourse = () =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
      let GET_COURSE = process.env.REACT_APP_API_GET_COURSES;
      let URL = URL_BASE+GET_COURSE;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
      setLoading(true)
      try{
          fetch(URL,{
              method:'get',
              mode: 'cors',
              headers: {
                  'Accept':'application/json',
                  'Authorization': 'Bearer ' + tokenLocalStorage,
                  'Content-type': 'application/json'
              }                
          }).then(function(res){
            if(res.status === 401){
              return 401;  
            }
              return res.json();
          }).then(function(response){
            setLoading(false)
            if(response != 401){
              if(response.Code === 0){
                setListCourses(response.Data)
              }else if (response.Code === 1){
                  var message = "";
                  if(response.Data.Errors.length > 0){
                      response.Data.Errors.forEach(element => {
                          message = `${element}.`
                      });
                      showErrorMessage(message);                        
                  }

              }else if (response.Code === 2){
                  var message = "";
                  if(response.Data.Errors.length > 0){
                      response.Data.Errors.forEach(element => {
                          message = `${element.FieldName}. ${element.Message}. `
                      });
                      showErrorMessage(message);                        
                  }
              }
            }
            else {
              setValidToken(true);
            }
  
          }).catch((error) =>{
              console.log(error);
          })
      }catch(e){
          console.log(e);
      }
    }

    const GetListCodigos = () =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_CODIGOS = process.env.REACT_APP_API_GET_LIST_ACTIVATE;
        let URL = URL_BASE+GET_CODIGOS+CourseId+'/'+GradeId+'/'+Activates+'/'+year;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        setLoading(true)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
              if(res.status === 401){
                return 401;  
              }
              return res.json();
            }).then(function(response){
                setLoading(false);
                if(response.Code === 0){
                    setListActivate(response.Data)
    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
                else if(response === 401){
                  setValidToken(true);
                }
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
    }

    const handleDateInit = (date) => {
      let month = date.month() + 1;
      let year = date.year();
      setDateInit(date);
      setYear(date.year());
    };

    const handleCloseActivation = () => {
      setOpenActivationsCode(false);   
    };

    const GetDowlandActivate = () =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
      let GET_Archive = process.env.REACT_APP_API_GET_EXPORT_ACTIVATE;
      let URL = URL_BASE+GET_Archive+CourseId+'/'+GradeId+'/'+Activates+'/'+year;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
      setLoading(true)
      try{
        fetch(URL, {
            method: 'get',
            headers: {
                'content-type': 'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                //'X-CSRF-TOKEN': getCookies("CSRF-TOKEN")
            }
            }).then( function (res){
              return res.blob();
            }).then( function (response){
              setLoading(false)
              let url = window.URL.createObjectURL(response);
              let a = document.createElement('a');
              a.href = url;
              a.download = 'ConsultaActivación.xlsx';
              a.click();
  
              }).catch(error =>
              {
                  console.log(error)
              });
      }catch(e){
          console.log(e);
      }
    }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Consulta de Activación</h1>
              </Grid>
            </Grid> 
            <div>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Curso</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="CursoId" id="CursoId" className="form-control" value={CourseId} onChange={handleChangeCourse}>
                        <option value="0">- Seleccione Curso -</option>
                          {
                          ListCourses.map((element,key) =>
                          <option value={element.IdCursoLibro}>{element.NombreCurso}</option>
                          )}   
                      </select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{paddingTop:15}} >
                  <a style={{fontSize:18}}>Grado</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="GradoId" id="GradoId" className="form-control" value={GradeId} onChange={handleChangeGrade}>
                      <option value="0">- Seleccione Grado -</option>
                        {
                          ListGrades.map((element,key) =>
                          <option key={element.IdGradoInstruccion} value={element.IdGradoInstruccion}>{element.NombreGrado}</option>
                        )} 
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingBottom:20}}>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Activos</a>
                </Grid>
                <Grid item xs={3} style={{textAlign:'left'}}>
                    <Checkbox
                        defaultChecked = {Activates}
                        color="primary"
                        inputProps={{ 'aria-label': 'secondary checkbox' }}
                        onChange={handleChangeActivate}
                    />
                </Grid>
                <Grid item xs={2} style={{paddingTop:15}}>
                  <a style={{fontSize:18}}>Periodo</a>
                </Grid>
                <Grid item xs={2} style={{textAlign:'left'}}>
                    <YearDatePicker
                    selectedDate={dateInit} 
                    handleDateChange={handleDateInit} />
                </Grid>
              </Grid>
            </div>
            <div>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={handleConsult}>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-nuevo" onClick={handleExport}>
                  Exportar
                </Button>
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left'}}>
                <Button variant="contained" className="btn-activacion" onClick={handleActivations}>
                  Activaciones
                </Button>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
            <Grid item xs={10} >
              <TableContainer component={Paper}></TableContainer>
              <Table className={classes.table, 'table-borde'} aria-label="customized table">
              <TableHead>
              <TableRow style={{border:'2px solid #dee2e6'}}>
                  <StyledTableCell>Código</StyledTableCell>
                  <StyledTableCell align="left">Título</StyledTableCell>
                  <StyledTableCell align="left">Codigo Lomo</StyledTableCell>
                  <StyledTableCell align="left">Grado</StyledTableCell>
                  <StyledTableCell align="left">Curso</StyledTableCell>
                  <StyledTableCell align="left">Usuario</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {
                  ListActivate.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key) =>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="table-border-right">
                      {element.CodigoActivacion}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Titulo}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Codigo_Lomo_Libro}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreGrado}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreCurso}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Usuario}</StyledTableCell>
                  </StyledTableRow>
                )}
                {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={6} style={{display:'none'}}/>
                </TableRow>
                )}
              </TableBody>              
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={ListActivate.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={1} >
            </Grid>
            </Grid>
            <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
            <UnauthorizedToken open={ValidToken} />
            <Progress open={loading} />
            <ActivationsCode open={OpenActivationsCode} handleClose={handleCloseActivation}/>
            </div>
        </div>
    )
}