import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Swal from 'sweetalert2';
import AdminUsuario from './AdminUsuario';
import ProgressIntoDialog from '../progress/Progress';
import FormControl from '@material-ui/core/FormControl';
import ShowDialog from '../dialogs/ShowDialog';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#00704a',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    btncancelar: {
      color: '#444 !important',
      backgroundColor: '#f8f9fa !important',
      border: '1px solid #ddd',
      boxShadow: 'none',
      textTransform: 'none !important',
      width: '50%',
      textAlign: 'left',
      marginLeft: 20,
    },
  });

export default function EditUsuario (props){
    const classes = useStyles();    
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [UserName, setUserName] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [MantUser, setMantUser] = React.useState(false);
    const [Email, setEmail] = React.useState('');
    const [Name, setName] = React.useState('');
    const [LastName, setLastName] = React.useState('');
    const [Document, setDocument] = React.useState('');
    const [GoMessageFinal, setGoMessageFinal] = React.useState(false);
    const [UserType, setUserType] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    
    React.useEffect(()=>{
        console.log(props.id);
        GetUser();
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleClose = () => {
      setShow(false);       
    };

    const handleFilterName = (e) =>{
      setName(e.target.value.toString());
    }
    const handleFilterLastName = (e) =>{
        setLastName(e.target.value.toString());
    }
    const handleFilterEmail = (e) =>{
        setEmail(e.target.value.toString());
    }
    const handleChangeUserType = (event) =>{
        setUserType(event.target.value);
    }
    const handleFilterDocument = (event) =>{
      setDocument(event.target.value);
    } 

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    function handleClick() {
      setGoMessageFinal(true);
    }

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    function handleClickSweet() {
      if(ValidateCampos())
      {
        if(validateFormatEmail(Email))
        {
          EditConfirm();
        }
        else
        {
          ShowDialogMessage('Correo con formato incorrecto.');
        }
      }
      else
      {
        ShowDialogMessage('Debes llenar los campos correctamente.')
      }
    }

    function validateFormatEmail (email) {
      let RegEx = /[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
      if(email.match(RegEx)){
          return true;
      }
      else{
        return false;
      }
    }

    const ValidateCampos = () =>{
      var valid = true;
      if (Name === "") {
        valid = false;
      }
      if (LastName === "") {
        valid = false;
      }
      if (Email === "") {
        valid = false;
      }
      if (UserType === "0") {
        valid = false;
      }
      if (Document === "") {
        valid = false;
      }
      else if (!valid){
        valid = false;
      }
      return valid;
    }

    function EditConfirm(){
        Swal.fire({
          title: 'Editar?',
          text: "¿Esta seguro que desea Editar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Si, Editar!'
        }).then((result) => {
          if (result.isConfirmed) {
            PostEditUser();
          }
        })
    }

    const PostEditUser = () =>{        
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let POST_EDIT_USER = process.env.REACT_APP_API_POST_EDIT_USER;
        let URL = URL_BASE+POST_EDIT_USER;        
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        
        var obj = {
          CodeUser: props.id,
          Nombres: Name,
          Apellidos: LastName,
          Email: Email,
          TipoUsuario: UserType,
          Documento: Document,
        }
        setLoading(true);
        try{
          fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
            }).then(function(res){
                return res.json();
            }).then(function(response){
                setLoading(false);
  
                if(response.Code === 0){                 
                    if(response.Data){                           
                      AlertLoad();             
                    }else{
                        ShowDialogMessage(response.Data.Message)
                    }
  
                }else if (response.Code === 1){
                        ShowDialogMessage(response.Message); 
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        ShowDialogMessage(message);                        
                    }
                }
  
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
      }

    function AlertLoad(){
        Swal.fire(
          'Hecho',
          'Se ha Editado Satisfactoriamente.',
          'success',
        ).then((result) => {
          setGoMessageFinal(true);
        })
      }


    if(newRegister){
        //return <NuevoPolitica  id={1}/>
    }

    if(EditRegister){
      //return <EditUser id={IdEdit}/>  /*id={1}*/
    }

    if(MantUser)
    {
      return <AdminUsuario/> 
    }
    

    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
  }

  const GetUser = () =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
      let GET_User = process.env.REACT_APP_API_GET_USERBYCODE;
      let URL = URL_BASE+GET_User+props.id;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
      try{
          fetch(URL,{
              method:'get',
              mode: 'cors',
              headers: {
                  'Accept':'application/json',
                  'Authorization': 'Bearer ' + tokenLocalStorage,
                  'Content-type': 'application/json'
              }                
          }).then(function(res){
  
              return res.json();
          }).then(function(response){
  
              if(response.Code === 0){
                  console.log(response.Data);
                  setUserName(response.Data.NombreUsuario);
                  setName(response.Data.Nombres);
                  setLastName(response.Data.Apellidos);
                  setEmail(response.Data.Email);
                  setUserType(response.Data.TipoUsuarioCod);
                  setDocument(response.Data.NumDoc);
  
              }else if (response.Code === 1){
                  var message = "";
                  if(response.Data.Errors.length > 0){
                      response.Data.Errors.forEach(element => {
                          message = `${element}.`
                      });
                      showErrorMessage(message);                        
                  }
  
              }else if (response.Code === 2){
                  var message = "";
                  if(response.Data.Errors.length > 0){
                      response.Data.Errors.forEach(element => {
                          message = `${element.FieldName}. ${element.Message}. `
                      });
                      showErrorMessage(message);                        
                  }
              }
  
          }).catch((error) =>{
              console.log(error);
          })
      }catch(e){
          console.log(e);
      }
  }

  if(GoMessageFinal){
    return <AdminUsuario/>
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Editar Usuario</h1>
              </Grid>
            </Grid> 
            <div style={{paddingBottom:20}}>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Login</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" readonly value={UserName} className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Nombres</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" value={Name} className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterName}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Apellidos</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" value={LastName} className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterLastName}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Correo</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" value={Email} className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterEmail}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Tipo Usuario</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="TypeUserId" id="TypeUserId" className="form-control" value={UserType} onChange={handleChangeUserType}>
                      <option value="0">- Seleccione Tipo Usuario -</option>
                      <option value="AD">Administrador</option>
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                  <a style={{fontSize:18}}>Documento</a>
                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} value ={Document} onChange={handleFilterDocument}/>
                </Grid>
              </Grid>
            </div>
            <div>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={handleClickSweet}>
                  Guardar
                </Button>
              </Grid>
              <Grid item xs={2} style={{paddingBottom:10, textAlign:'left'}}>
                <Button variant="contained" className={classes.btncancelar} onClick={handleClick}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
            </div>
            <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
            <ProgressIntoDialog open={loading} />
        </div>
    )
}