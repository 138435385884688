import React, { lazy } from 'react';
import WidgetsDropdown from '../widgets/WidgetsDropdown.js'
import MainChartExample from '../charts/MainChartExample'
import {
        CBadge,
        CButton,
        CButtonGroup,
        CCard,
        CCardBody,
        CCardFooter,
        CCardHeader,
        CCol,
        CProgress,
        CRow,
        CCallout,
        CWidgetDropdown
    } from '@coreui/react'
    import CIcon from '@coreui/icons-react';
//import ChartLineSimple from '../charts/ChartLineSimple.js';
//import ChartBarSimple from '../charts/ChartBarSimple.js';
import VerticalBarChart from '../bar/VerticalBarChart';
import HorizontalBarChart from '../bar/HorizontalBarChart';
import add  from '../funciones/Add';
//import DatePicker from 'react-date-picker';
import MonthDatePicker from '../date/MonthDatePicker.js';
import {MainContext} from '../../context/MainAppProvider';
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/ShowDialog';
import UnauthorizedDialog from '../dialogs/UnauthorizedToken';
import ConstantAction from '../../config/ConstantAction';

//const WidgetsDropdown = lazy(() => import('../widgets/WidgetsDropdown.js'))

const Dashboard = () => {

    const OtherComponent = React.lazy(() => import('../funciones/Add'));
    const {getDateLocal,getYearToday,getMonthToday}  = React.useContext(MainContext);
    const [dateInit, setDateInit] = React.useState(new Date(getDateLocal()));
    const [year, setYear] = React.useState(getYearToday());
    const [month, setMonth] = React.useState(getMonthToday());

    const [objAdvanceTeacher, setObjAdvanceTeacher] = React.useState({arrayLabels: [],arrayData:[]});
    const [objAdvanceStudent, setObjAdvanceStudent] = React.useState({arrayLabels: [],arrayData:[]});
    const [objSchoolUser, setObjSchoolUser] = React.useState({arrayLabels: [],arrayData:[]});
    const [objAdvanceAverageTeacherBook, setObjAdvanceAverageTeacherBook] = React.useState({
        arrayLabels: [],
        arrayData:[]
    });
    const [objUsePlatformByDay, setObjUsePlatformByDay]=React.useState({
        arrayTeacher:[],
        arrayStudent: [],
        arrayDays: [],
        maxElement: 100
    })
    //----loader----
    const [loading, setLoading] = React.useState(false);
    //---message error-----
    const [show, setShow] = React.useState(false);
    const [messageError, setMessageError] = React.useState(false);
    //------------------------flag vencimiento de token------------------------------
    const [showUnauthorized, setShowUnauthorized] = React.useState(false);
    //------------
    const handleClose = () => {
        setShow(false)       
    };
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    //----------- impl method async ------------------------------------
    const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;

        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
        const paramsHeadersPost = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }
        const paramsHeadersGet = {
        method:typeCall,
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        }
        }
        const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
        const URL = URL_BASE+methodAction;    
        setLoading(true);
        try{
            fetch(URL,header).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();

            }).then(function(response){    
                setLoading(false);
                if(response === 401){
                    setShowUnauthorized(true);

                }else if(response.Code === 0){
                    SetConfigResponse(response,typeAction);                    

                }else if (response.Code === 1){
                    errorCode1(response);
            
                }else if (response.Code === 2){
                    errorCode2(response);        
                }
            }).catch((error) =>{
                setLoading(false);
                console.log(error);
            })
        }catch(e){
            setLoading(false);
            console.log(e);
        }                 
    }
    const SetConfigResponse = (response,typeAction)=>{
        if(typeAction === ConstantAction.ACTION_GET_REPORT_TEACHER_BY_STUDENT){
            ResponseGetReportTeacherByStudent(response);

        }else if (typeAction === ConstantAction.ACTION_GET_ADVANCE_BOOK_STUDENT){
            ResponseAdvanceBookStudent(response);

        }else if (typeAction === ConstantAction.ACTION_GET_ADVANCE_BOOK_TEACHER){
            ResponseAdvanceBookTeacher(response);

        }else if (typeAction === ConstantAction.ACTION_GET_SCHOOL_USERS){
            ResponseSchoolUsers(response);

        }else if (typeAction === ConstantAction.ACTION_GET_ADVANCE_AVERAGE_TEACHER_BOOK){
            ResponseAdvanceAverageTeacherBook(response);
        }
    }
    const errorCode1 = (response) =>{
        var message = "";
        if(response.Data.StatusCode === 500){
            ShowDialogMessage(response.Data.Message);
        }
        else if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element}.`
            });
            ShowDialogMessage(message);                        
        }
    }
    const errorCode2 = (response) =>{
        var message = "";
        if(response.Data.Errors.length > 0){
            response.Data.Errors.forEach(element => {
                //console.log(element);
                message = `${element.FieldName}. ${element.Message}. `
            });
            ShowDialogMessage(message);                        
        }
    }
  //--------------init--------------------------------  
    React.useEffect(() =>{
        //add(16,26);
        //console.log(OtherComponent)
        GetReportTeacherByStudent(year,month);
        GetAdvanceBookStudent();
        GetAdvanceBookTeacher();
        GetSchoolUsers();
        GetAdvanceAverageTeacherBook();
    },[])
    /**************** API DATA USO DE PLATAFORMA POR DÍA DOCENTE X ALUMNO*/
    const GetReportTeacherByStudent = (year,month) => {
        let methodAction = process.env.REACT_APP_GET_REPORT_TEACHER_BY_STUDENT.toString() + year.toString() + "/" + month.toString();
        asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_REPORT_TEACHER_BY_STUDENT);
    }
    const ResponseGetReportTeacherByStudent = (response) =>{
        //console.log(response);
        const res = {
            Data: [
                {
                    docente: 45,
                    alumno: 20,
                    dia: 'Lu'
                },
                {
                    docente: 590,
                    alumno: 30,
                    dia: 'Ma'
                },
                {
                    docente: 23,
                    alumno: 400,
                    dia: 'Mi'
                },
                {
                    docente: 15,
                    alumno: 35,
                    dia: 'Ju'
                },
                {
                    docente: 100,
                    alumno: 55,
                    dia: 'Vi'
                }
            ]
        }

        let arrT = [];
        let arrS = [];
        let arrD = [];
        let max = 0;
        response.Data.forEach(element => {
            arrT.push(element.docente);
            arrS.push(element.alumno);
            arrD.push(element.FormatDia);

            if(max < element.docente){
                max = element.docente;
            }
            if(max < element.alumno){
                max = element.alumno;
            }
        });
        setObjUsePlatformByDay({
            arrayTeacher: arrT,
            arrayStudent: arrS,
            maxElement: max,
            arrayDays: arrD
        })
        //console.log(objUsePlatformByDay);
    }
    /**************** API DATA AVANCE DE LIBRO POR ALUMNO, TOP 5 */
    const GetAdvanceBookStudent = () =>{
        let methodAction = process.env.REACT_APP_GET_ADVANCE_BOOK_STUDENT;
        asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_ADVANCE_BOOK_STUDENT);
    }
    const ResponseAdvanceBookStudent = (response) =>{
        //console.log(response);
        const res = {
            Data: [
                {
                    Avance: 1.5,
                    NombreLibro: "LibroRojo"
                }
            ]
        }
        let arrLabels = [];
        let arrData = [];
        response.Data.forEach(element => {
            arrLabels.push(element.NombreLibro);
            arrData.push(element.Avance);
        });
        setObjAdvanceStudent({
            arrayLabels: arrLabels,
            arrayData:arrData
        })
    }
    /**************** APID DATA AVACE DE LIBRO POR DOCENTE, TOP 5 */
    const GetAdvanceBookTeacher = () =>{
        let methodAction = process.env.REACT_APP_GET_ADVANCE_BOOK_TEACHER;
        asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_ADVANCE_BOOK_TEACHER);
    }
    const ResponseAdvanceBookTeacher = (response) =>{
        //console.log(response);
        const res = {
            Data: [
                {
                    Avance: 1.5,
                    NombreLibro: "LibroRojo"
                }
            ]
        }
        let arrLabels = [];
        let arrData = [];
        response.Data.forEach(element => {
            arrLabels.push(element.NombreLibro);
            arrData.push(element.Avance);
        });
        setObjAdvanceTeacher({
            arrayLabels: arrLabels,
            arrayData:arrData
        })
    }
    /*************** API DATA COLEGIO CON MÁS USUARIOS (DOCENTES Y ALUMNOS), TOP 5 */
    const GetSchoolUsers = () =>{
        let methodAction = process.env.REACT_APP_GET_SCHOOL_USERS;
        asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_SCHOOL_USERS);
    }
    const ResponseSchoolUsers = (response) =>{
        //console.log(response);
        let arrLabels = [];
        let arrData = [];
        response.Data.forEach(element => {
            arrLabels.push(element.NombreColegio);
            arrData.push(element.CantidadUsuarios);
        });
        setObjSchoolUser({
            arrayLabels: arrLabels,
            arrayData:arrData
        })
    }
    /****************API DATA DOCENTES CON MEJOR PROMEDIO DE AVANCE DE LIBROS, TOP 5 */
    const GetAdvanceAverageTeacherBook = () =>{
        let methodAction = process.env.REACT_APP_GET_ADVANCE_AVERAGE_TEACHER_BOOK;
        asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_ADVANCE_AVERAGE_TEACHER_BOOK);
    }
    const ResponseAdvanceAverageTeacherBook = (response) => {
        //console.log(response);
        const res = {
            Data:[
                {
                    AvancePromedio: 1.8,
                    Docente: "Docente 1"
                }
            ]
        }
        let arrE = [];
        let arrD = [];
        response.Data.forEach(element => {
            arrE.push(element.Docente);
            arrD.push(element.AvancePromedio)
        });
        setObjAdvanceAverageTeacherBook({
            arrayLabels: arrE,
            arrayData: arrD
        });
    }
    //----------- HANDLE EVENT ----------------------
    const handleDateInit = (date) => {
        //console.log('date');
        //console.log(date);
        let month = date.month() + 1;
        let year = date.year();
        setDateInit(date);
        setYear(date.year());
        setMonth(month)
        GetReportTeacherByStudent(year,month);
    };
    

    return (
        <>
            <WidgetsDropdown />
            <CRow className="m-3">
                <CCol xs="12" md="3" xl="2">
                <div className="mt-2">
                    Periodo
                </div>
                </CCol>
                <CCol xs="12" md="3" xl="2">
                <div>
                    <MonthDatePicker 
                    selectedDate={dateInit} 
                    handleDateChange={handleDateInit} />
                </div>
                </CCol>
            </CRow>

            <CCard>
                <CCardBody>
                    <CRow>
                        <CCol sm="12">
                            <h4 id="traffic" className="card-title mb-0">Uso de plataforma por día</h4>                
                        </CCol>
                        <CCol sm="7" className="d-none d-md-block2 hideDiv">
                            <CButton color="primary" className="float-right">
                                <CIcon name="cil-cloud-download"/>
                            </CButton>
                            <CButtonGroup className="float-right mr-3">
                                {
                                ['Day', 'Month', 'Year'].map(value => (
                                    <CButton
                                    color="outline-secondary"
                                    key={value}
                                    className="mx-0"
                                    active={value === 'Month'}
                                    >
                                    {value}
                                    </CButton>
                                ))
                                }
                            </CButtonGroup>
                        </CCol>
                    </CRow>
                    <MainChartExample 
                    style={{height: '300px', marginTop: '40px'}}
                    arrayTeacher= {objUsePlatformByDay.arrayTeacher}//{[45, 290, 23, 15, 100]}
                    arrayStudent={objUsePlatformByDay.arrayStudent}//{[20, 30, 400, 35, 55]}
                    maxElement={objUsePlatformByDay.maxElement} //{100}
                    days={objUsePlatformByDay.arrayDays}//{['Lu', 'Ma', 'Mi', 'Ju', 'Vi']}
                    />
                </CCardBody>
                <CCardFooter>
                    <CRow className="text-center">
                        <CCol md sm="12" className="mb-sm-2 mb-0">
                            <div className="text-muted">Docente</div>
                            <CProgress
                                className="progress-xs mt-2"
                                precision={1}
                                color="success"
                                value={40}
                            />
                        </CCol>
                        <CCol md sm="12" className="mb-sm-2 mb-0 d-md-down-none">
                            <div className="text-muted">Alumno</div>
                            <CProgress
                                className="progress-xs mt-2"
                                precision={1}
                                color="info"
                                value={40}
                            />
                        </CCol>
                    </CRow>
                </CCardFooter>        
            </CCard>

            <CRow>

                <CCol xs="12" md="6" xl="6">                    
                    <CCard>
                        <CCardHeader>
                        Avance de Libro Alumno
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="12">
                                    <VerticalBarChart
                                    //arrayElement={['Alumno 1','Alumno 2','Alumno 3','Alumno 4','Alumno 5']} 
                                    arrayElement = {objAdvanceStudent.arrayLabels}
                                    arrayData={objAdvanceStudent.arrayData}
                                    title={'Libro X Alumno'}/>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol xs="12" md="6" xl="6">
                    <CCard>
                        <CCardHeader>
                        Avance de Libro Docente
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="12">
                                    <VerticalBarChart 
                                    arrayElement={objAdvanceTeacher.arrayLabels}
                                    arrayData={objAdvanceTeacher.arrayData}
                                    title={'Libro X Docente'}/>                                
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>                    
                </CCol>

                <CCol xs="12" md="6" xl="6">
                    <CCard>
                        <CCardHeader>
                        Colegios con más usuarios (docentes y alumnos)
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="12">
                                    <HorizontalBarChart 
                                    arrayLabels={objSchoolUser.arrayLabels}//arrayLabels={['Red', 'Blue', 'Yellow', 'Green', 'Purple', 'Orange']}
                                    arrayData={objSchoolUser.arrayData}//arrayData={[12, 19, 3, 5, 2, 3]}
                                    title="dddddd"
                                    titleGraphic={'Colegio Top'}/>
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>

                <CCol xs="12" md="6" xl="6">
                    <CCard>
                        <CCardHeader>
                        Docentes con mejor promedio de avance de libros
                        </CCardHeader>
                        <CCardBody>
                            <CRow>
                                <CCol xs="12">
                                    <HorizontalBarChart 
                                        arrayLabels={objAdvanceAverageTeacherBook.arrayLabels}
                                        arrayData={objAdvanceAverageTeacherBook.arrayData}
                                        title="sdvds"
                                        titleGraphic={'Docente Top'}/>                                
                                </CCol>
                            </CRow>
                        </CCardBody>
                    </CCard>
                </CCol>
            </CRow>

            <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
            <Progress open={loading} />
            <UnauthorizedDialog open={showUnauthorized} />
        </>
    )
}

export default Dashboard

