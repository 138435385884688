import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ShowDialog from '../dialogs/ShowDialog';
import ProgressIntoDialog from '../progress/Progress';
import Swal from 'sweetalert2';

const styles = (theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[900],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
      <MuiDialogTitle disableTypography className={classes.root} {...other}>
        <Typography variant="h6">{children}</Typography>
        {onClose ? (
          <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        ) : null}
      </MuiDialogTitle>
    );
  });

const DialogContent = withStyles((theme) => ({
    root: {
      padding: theme.spacing(2),
    },
}))(MuiDialogContent);
  
const DialogActions = withStyles((theme) => ({
    root: {
      margin: 0,
      padding: theme.spacing(1),
    },
}))(MuiDialogActions);
  
const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    btncancelar: {
      color: '#444 !important',
      backgroundColor: '#f8f9fa !important',
      border: '1px solid #ddd',
      boxShadow: 'none',
      textTransform: 'none !important',
      width: '30%',
      textAlign: 'left',
      marginLeft: 20,
    },
    btnenviar: {
      color: '#ffff !important',
      background: 'rgb(0, 98, 204) !important',
      border: '1px solid #ddd',
      boxShadow: 'none',
      textTransform: 'none !important',
      width: '30%',
      textAlign: 'left',
      marginLeft: 20,
    },
});

export default function NewPromoterModel(props) {
    const [open, setOpen] = React.useState(false);
    const classes = useStyles();   
    const [show, setShow] = React.useState(false);
    const [NombrePromotor, setNombrePromotor] = React.useState('');
    const [Apellido, setApellido] = React.useState('');
    const [loading, setLoading] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [GoMessageFinal, setGoMessageFinal] = React.useState(false);

    const handleClose = () => {
        props.handleClose(true);
        setShow(false);
    };
    
    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }
    
    const handleKeyCurrent = (e) =>{
        setNombrePromotor(e.target.value.toString());
    }
    
    const handleKeyNew = (e) =>{
        setApellido(e.target.value.toString());
    }
    
    const handlePassword = () => {
        if(ValidateCampos())
        {
            PostInsertPromoter();
        }
        else
        {
            ShowDialogMessage('Debe Completar los Campos.')
        }
    }
    
    const ValidateCampos = () =>{
        var valid = true;
        if (NombrePromotor === "") {
          valid = false;
        }
        if (Apellido === "") {
          valid = false;
        }
        else if (!valid){
          valid = false;
        }
        return valid;
    }
    
    function AlertLoad(){
        props.handleClose(true);
        Swal.fire(
          'Hecho',
          'Se ha Guardado Satisfactoriamente.',
          'success',
        ).then((result) => {
          setGoMessageFinal(true);
          setNombrePromotor("");
          setApellido("");
        })
    }

    const PostInsertPromoter = () =>{        
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let POST_UPDATE = process.env.REACT_APP_API_POST_INSERTARPROMOTER;
        let URL = URL_BASE+POST_UPDATE;        
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        
        var obj = {
            NombrePromotor: NombrePromotor,
            Apellido: Apellido,
        }
        setLoading(true);
        try{
          fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
            }).then(function(res){
                return res.json();
            }).then(function(response){
                setLoading(false);
    
                if(response.Code === 0){  
    
                    if(response.Data==true){                           
                      AlertLoad();                             
                    }else if(response.Data==false){
                      ShowDialogMessage("El Promotor ya Existe");
                    }else{
                      ShowDialogMessage(response.Message)
                    }
    
              
    
                }else if (response.Code === 1){
                    var message = "";
                    if(!response.Data){
                        ShowDialogMessage(response.Message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        ShowDialogMessage(message);                        
                    }
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    return (
        <div>
          <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
                Nuevo Registro de Promotores
            </DialogTitle>
            <DialogContent dividers>
                <Typography gutterBottom style={{display:'none'}}>
                    Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                    in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
                </Typography>
    
                <Typography gutterBottom>
                    <Grid container>
                        <Grid item xs={4} style={{paddingTop:15}} >
                            <a style={{fontSize:15}}>Nombre del Promotor:</a>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-margin-dense" margin="dense" label="" variant="outlined" style={{width:200}} onChange={handleKeyCurrent}/>
                        </Grid>
                    </Grid>
    
                    <Grid container>
                        <Grid item xs={4} style={{paddingTop:15}} >
                            <a style={{fontSize:15}}>Apellido del Promotor:</a>
                        </Grid>
                        <Grid item xs={4}>
                            <TextField id="outlined-margin-dense" margin="dense" label="" variant="outlined" style={{width:200}} onChange={handleKeyNew}/>
                        </Grid>
                    </Grid>
    
        
                </Typography>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handlePassword} className={classes.btnenviar} color="primary">
                Enviar
              </Button>
              <Button autoFocus onClick={handleClose} className={classes.btncancelar} color="primary">
                Cancelar
              </Button>
            </DialogActions>
          </Dialog>
          <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
          <ProgressIntoDialog open={loading} />
        </div>
    );

}