import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Cookies from 'js-cookie';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Redirect } from 'react-router';
import MoreIcon from '@material-ui/icons/MoreVert';
import ChangePassword from "../dialogs/ChangePassword";
const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({  
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
}));

const useStyles_menu = makeStyles(theme => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  toolbar: {
    alignItems: 'flex-start',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
  },
  title: {
    flexGrow: 1
  },
}));

export default function ToolbarDrawer (props){

  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const [openChangePassword, setOpenChangePassword] = React.useState(false);
  const [logout, setLogout] = React.useState(false);
  //---
  const [loading, setLoading] = React.useState(false);
  //---message error
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  const [showing, setShowing] = React.useState(false);
  //--
  const [unauthorized, setUnauthorized] = React.useState(false);

  const showErrorMessage = (msg) => {
    if (showing){
        return;
    }

    setShow(true);
    setShowing(true);
    setMessageError(msg);

    setTimeout(() => {
        setShow(false);
        setShowing(false);
        setMessageError('');            
    }, 4000);
  }

  const handleClickMoreIcon = event => {
    setAnchorEl(event.currentTarget);
  };

  const handleVersionApp = () => {
    setAnchorEl(null);
    setOpen(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleCloseDialog = () =>{
    setOpen(false);
  }

  const handleChangePassword = () =>{
    setOpenChangePassword(true);
  }

  const handleCloseChangePassword = () => {
    setOpenChangePassword(false);   
  };

  const handleLogout = () =>{
    setAnchorEl(null);
    setLoading(true);
    var obj = {
      Token:localStorage.getItem(process.env.REACT_APP_KEY_TOKEN),
      RefreshToken: localStorage.getItem(process.env.REACT_APP_KEY_REFRESH_TOKEN)
    }

    const URL_BASE = process.env.REACT_APP_URL_BASE;
    let POST_LOGOUT = process.env.REACT_APP_API_POST_LOGOUT;
    let URL = URL_BASE+POST_LOGOUT;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    const Apikey = process.env.REACT_APP_API_KEY;
    try{
        fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'ApiKey': Apikey,
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(function(res){
            if(res.status == 401){
              return 401;
            }
            return res.json();
        }).then(function(response){
            setLoading(false);
            if(response != 401){
              if(response){

                localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, 'key-token');
                localStorage.setItem(process.env.REACT_APP_KEY_REFRESH_TOKEN, 'key-refresh-token');

                setLogout(true);
              }else{
                showErrorMessage('ocurrió un error')
              }

            }else{
              setUnauthorized(true);
            }            

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  let nameToolbar = props.name; 

  if(props.param === 7){
    nameToolbar = props.name;
  }

  if(logout){
    return <Redirect to="/"/>
  }
  

  return(
    <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
        [classes.appBarShift]: props.open,
        })}
    >
        <Toolbar>
          <IconButton
              color="red"
              aria-label="open drawer"
              onClick={props.handleDrawerOpen}
              edge="start"
              className={clsx(classes.menuButton, {
              [classes.hide]: props.open,
              })}
          >
              <MenuIcon />
          </IconButton>

          <Typography
            variant="h6"
            noWrap
            style={{width:'90%',textAlign:"left", color:'rgba(0,0,0,.5)', fontWeight: 'bolder'}}
          >
              {nameToolbar}
          </Typography>

          <IconButton
            aria-label="display more actions" 
            edge="end" 
            color="black"
            aria-controls="simple-menu"
            aria-haspopup="true"
            onClick={handleClickMoreIcon}
          >
              <MoreIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >            
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
            <MenuItem onClick={handleChangePassword}>Cambiar Contraseña</MenuItem>            
          </Menu>
        </Toolbar>
        <ChangePassword open={openChangePassword} handleClose={handleCloseChangePassword}/>
    </AppBar>
  );  
}