import React from 'react'
import { Grid } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';

export default function Welcome (props){
    
    const useStyles = makeStyles((theme) => ({
        root: {
          '& > *': {
            margin: theme.spacing(1),
            width: '50ch',
          },
        },
      }));

    const classes = useStyles();
    return (       
      <div style={{marginTop:20,marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
        <Grid container>
            <Grid item xs={12} style={{borderTop:1, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:1, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                <h1 style={{margin:5, fontSize:'16pt', textAlign:'left', paddingLeft:15}}>Santo Domingo</h1>
            </Grid>
            <Grid item xs={12} style={{paddingTop:30, paddingBottom:10}}>
              <h3 style={{textAlign:'left', paddingLeft:15}}>Bienvenido al Sistema.</h3>
            </Grid>
        </Grid>
      </div>        
    )
}