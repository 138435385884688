import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
//import "./Book.css";
import "./School.css";
import NewSchoolModel  from "../dialogs/NewSchool";
import EditSchoolModel from "../dialogs/EditSchoolModel";

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);

  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
  });


export default function School (props){
    const classes = useStyles();    
    const [param, setParam] = React.useState(4);
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [ListUser, setListUser] = React.useState([]);
    const [IdEdit, setIdEdit] = React.useState(0);
    const [NameCall, setNameCall] = React.useState('');
    const [UserCodeDelete, setUserCodeDelete] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [StatusDelete, setStatusDelete] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [Name, setName] = React.useState('-');
    const [UserType, setUserType] = React.useState(0);
    const [ListCourses, setListCourses] = React.useState([]);
    const [ListGrades, setListGrades] = React.useState([]);
    const [ListBooks, setListBooks] = React.useState([]);
    const [NombreColegio, setNombreColegio] = React.useState('-');
    const [openSchool, setOpenNewSchool] = React.useState(false);
    const [openEditSchool,setopenEditSchool]=React.useState(false);
    const [IdColegio, setIdColegio] =  React.useState(0);

    React.useEffect(()=>{
      //GetListCourse();
      //GetListGrade();
      GetListSchool();
    },[])


    const NewSchoolName = () => {
      setOpenNewSchool(true);
    };

    const handleCloseSchool = () => {
      setOpenNewSchool(false);   
      GetListSchool();
    };
    

    const EditSchoolName=()=>{
      GetListSchool();
      setopenEditSchool(false);
      setIdEdit(0);  
    };

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    function handleClick() {
      console.log("entro al nuevo");
      setNewRegister(true);
    }

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    function ClickConsultar() {
      GetListSchool();
    }

    const handleClickEdit = (e) =>{
      console.log('llamando al get');
      console.log(e.currentTarget.id);
      setOpenNewSchool(false);
      setIdEdit(e.currentTarget.id);
      setStatusDelete(false);
      //setEditRegister(true);
      setopenEditSchool(true);
    }

    const handleClickDelete = (e) =>{      
      setIdColegio(e.currentTarget.id);
      setStatusDelete(true);

       console.log(e.currentTarget.id);

    }
    if(StatusDelete){
      DeleteConfirm();
    }

    function DeleteConfirm(){
      if(IdColegio.length > 0){
        Swal.fire({
          title: 'Eliminar',
          text: "¿Esta seguro que desea Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'

        }).then((result) => {
          console.log(result);
          if (result.isConfirmed) {
            PostDeleteBook();
            setStatusDelete(false);
            Swal.fire(
              'Eliminado',
              'Se Eliminó Correctamente.', 
              'success'
            )
          }
          else{
            setStatusDelete(false);
          }
        })
       }
    }

    
    const handleFilterTitle = (e) =>{
      if(e.target.value.toString() == "")
      {
        setNombreColegio('-');
      }
      else{
        setNombreColegio(e.target.value.toString());
      }
    }
    const handleChangeUserType = (event) =>{
      setUserType(event.target.value);
    }



    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
  }


  const PostDeleteBook = () =>{   
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let POST_DELETESCHOOL = process.env.REACT_APP_API_POST_DELETESCHOOL;
    let URL = URL_BASE+POST_DELETESCHOOL;     
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    var obj = {
      IdColegio: parseInt(IdColegio),
      
    }
    try{
      fetch(URL,{
        method:'post',
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }).then(function(res){
            return res.json();
        }).then(function(response){
            if(response.Code === 0){                    
              GetListSchool();                     
              setStatusDelete(false);   
              console.log(response.Code);         

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

 


  const GetListSchool = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_SCHOOL = process.env.REACT_APP_API_GET_SCHOOL;
    let URL = URL_BASE+GET_SCHOOL+ NombreColegio ;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){

            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListBooks(response.Data)

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }




  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Mantenedor de Colegios</h1>
              </Grid>
            </Grid> 

     
            <div style={{paddingBottom:20}}>
              <Grid container style={{marginTop:30}}>
               
                <Grid item xs={1} >
                </Grid>

                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:16}}>Nombre del Colegio</a>
                </Grid>

                <Grid item xs={3}>
                  <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterTitle}/>
                </Grid>
              </Grid>

            </div>
           
            <div>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={ClickConsultar}>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left'}}>
                <Button  variant="contained" className="btn-nuevo" onClick={NewSchoolName}>
                  Nuevo
                </Button>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
           
            <Grid item xs={10} >
                
              <TableContainer component={Paper}></TableContainer>
              <Table className={classes.table, 'table-borde'} aria-label="customized table">
              <TableHead>
              <TableRow style={{border:'2px solid #dee2e6'}}>
                  <StyledTableCell>Acciones</StyledTableCell>
                 
                  <StyledTableCell align="left">NOMBRE COLEGIO</StyledTableCell>
                  <StyledTableCell align="left">DIRECCION</StyledTableCell>
                  <StyledTableCell align="left">Promotor</StyledTableCell>
                  <StyledTableCell align="left">ESTADO</StyledTableCell>
                  
              </TableRow>
              </TableHead>

              <TableBody>
                {
                  ListBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key) =>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="table-border-right">
                        <Grid item>
                          <Button id={element.IdColegio} onClick={(e) => handleClickEdit(e)} style={{minWidth:40}}/*onClick={handleClickEdit}*/>
                            <BorderColorSharpIcon/>
                          </Button>


                          <Button id={element.IdColegio} onClick={(e) => handleClickDelete(e)} style={{minWidth:40}}>
                          <DeleteOutlinedIcon />
                          </Button>
                        </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="left" style={{display:'none'}}  className="table-border-right">{element.IdColegio}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreColegio}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Direccion}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Promotor}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Estado}</StyledTableCell>
                   
                  </StyledTableRow>
                )}

                {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={6} style={{display:'none'}}/>
                </TableRow>
                )}

              </TableBody>              
              </Table>
              
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={ListBooks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={1} >
            </Grid>
            </Grid>
            <EditSchoolModel open={openEditSchool} handleClose={EditSchoolName} id={IdEdit}/>
            <NewSchoolModel open={openSchool} handleClose={handleCloseSchool}/>
            </div>
        </div>
    )
}