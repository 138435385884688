import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ProgressIntoDialog from '../progress/Progress';
import Swal from 'sweetalert2';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    btncancelar: {
        color: '#444 !important',
        backgroundColor: '#f8f9fa !important',
        border: '1px solid #ddd',
        boxShadow: 'none',
        textTransform: 'none !important',
        width: '20%',
        textAlign: 'left',
        marginLeft: 20,
    },
    btnaprobar: {
        color: '#fff !important',
        backgroundColor: '#007bff !important',
        border: '1px solid #ddd',
        boxShadow: 'none',
        textTransform: 'none !important',
        width: '20%',
        textAlign: 'left',
    },
  });

export default function EditSchoolModel(props) {
    const classes = useStyles();    
    const [CompanyId, setCompanyId] = React.useState(0);
    const [CountryId, setCountryId] = React.useState(0);
    const [NamePolitic, setNamePolitic] = React.useState('');
    const [NameArchivePolitic, setNameArchivePolitic] = React.useState('');
    const [RouteArchive, setRouteArchive] = React.useState('');
    const [FileEdit, setFileEdit] = React.useState("");
    const [OpenLoad, setOpenLoad] = React.useState(false);
    const [CodePolitic, setCodePolitic] = React.useState('');
    const [NombreColegio, setNombreColegio] = React.useState('');
    const [Direccion, setDireccion] = React.useState('');
    const [IdColegio, setIdColegio] = React.useState(0);
    const [loading, setLoading] = React.useState(false);
    const [show, setShow] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [ValidChange, setValidChange] = React.useState(true);
    const [ListPromotor, setListaPromotor] = React.useState([]);
    const [PromotorId, setPromotorId] = React.useState(0);

    React.useEffect(()=>{
        if (props.id >= 1) {
            //GetListPromotor();
            if (ValidChange) {
              GetListSchoolParams(props.id);    
              GetListPromotor();
            }
            setIdColegio(props.id);

        };
    });

    const handleClose = () => {
        setNameArchivePolitic('');
        setRouteArchive('');
        setNamePolitic('');
        setCountryId(0);
        setCompanyId(0);
        props.handleClose(true);
        setValidChange(true);
    };

    const GetListPromotor = () =>{
      const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
      let GET_DEPARTMENT = process.env.REACT_APP_API_GET_LIST_Promotor;
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
      let URL = URL_BASE+GET_DEPARTMENT;
      try{
          fetch(URL,{
              method:'get',
              mode: 'cors',
              headers: {
                  'Accept':'application/json',
                  'Authorization': 'Bearer ' + tokenLocalStorage,
                  'Content-type': 'application/json'
              }                
          }).then(function(res){
             
              return res.json();
          }).then(function(response){
  
              if(response.Code === 0){
                setListaPromotor(response.Data)
                //console.log(response.Data);
              } 
  
          }).catch((error) =>{
              console.log(error);
          })
      }catch(e){
          console.log(e);
      }
    }


    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    const handleChangeCountry = (event) =>{
        setCountryId(event.target.value);
    }


    const handleNombreDireccion= (e) =>{
      setValidChange(false);
      setDireccion(e.target.value.toString());
    }


    const handleNombreColegio= (e) =>{
    console.log(e.target.value.toString());
    setValidChange(false);
    setNombreColegio(e.target.value.toString());
   }


    const showErrorMessage = (msg) => {
        /*if (showing){
            return;
        }

        setShow(true);
        setShowing(true);
        setMessageError(msg);

        setTimeout(() => {
            setShow(false);
            setShowing(false);
            setMessageError('');            
        }, 4000);*/
    }

    function AlertLoad(){
        handleClose();
        Swal.fire(
          'Hecho',
          'Archivo Guardado Satisfactoriamente.',
          'success'
        ).then((result) => {
            handleClose();
        })
      }

      const handleChangeGrade = (event) => {
        setPromotorId(event.target.value);
      }

      const GetListSchoolParams = (id) =>{
        console.log('llamanod');
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_SCHOOL = process.env.REACT_APP_API_GET_SCHOOL_PARAMS;
        let URL = URL_BASE+GET_SCHOOL+id ;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
    
                return res.json();
            }).then(function(response){
    
                if(response.Code === 0){
                  setDireccion(response.Data.Direccion);
                  setNombreColegio(response.Data.NombreColegio);
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
      }

    

    const PostEditSchool = () =>{        

        console.log("entra al editar");
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let POST_UPDATE = process.env.REACT_APP_API_POST_EDITARSCHOOL;
        let URL = URL_BASE+POST_UPDATE;        
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        
        var obj = {
            IdColegio: parseInt(IdColegio),
            NombreColegio: NombreColegio,
            Direccion: Direccion,
            PromotorId: parseInt(PromotorId)
        }
        setLoading(true);
        try{
          fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
            }).then(function(res){
                return res.json();
            }).then(function(response){
                setLoading(false);
    
                if(response.Code === 0){  
    
                    if(response.Data==true){                           
                      AlertLoad();                             
                    }else if(response.Data==false){
                      ShowDialogMessage("Colegio ya Existe");
                    }else{
                      ShowDialogMessage(response.Message)
                    }
    
              
    
                }else if (response.Code === 1){
                    var message = "";
                    if(!response.Data){
                        ShowDialogMessage(response.Message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        ShowDialogMessage(message);                        
                    }
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }
    
    
    
     
    

    const handleLoadEdit = () => {
      PostEditSchool();
    };

    const showDialogError = (message) =>{        
        console.log(message);
    }

  return (
    <div>
      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose} onClick={handleClose} >
            Editar Colegios
        </DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom style={{display:'none'}}>
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </Typography>
            <Typography gutterBottom>
                <Grid container>
                    <Grid item xs={4} style={{paddingTop:15}} >
                        <a style={{fontSize:15}}>Nombre del Colegio:</a>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'200%'}} onChange={handleNombreColegio} value={NombreColegio} />
                    </Grid>
                </Grid>
                
                <Grid container>
                    <Grid item xs={4} style={{paddingTop:15}} >
                        <a style={{fontSize:15}}>Direccion:</a>
                    </Grid>
                    <Grid item xs={4}>
                        <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'200%'}} onChange={handleNombreDireccion} value={Direccion} />      
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} style={{paddingTop:15}} >
                        <a style={{fontSize:15}}>Promotor:</a>
                    </Grid>
                    <Grid item xs={4}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="PromotorId" id="PromotorId" className="form-control" value={PromotorId} onChange={handleChangeGrade}>
                        <option key='24' value="0">- Seleccione Promotor -</option>
                        {
                        ListPromotor.map((element,key) =>
                        <option key={element.Idpromotor} value={element.Idpromotor}>{element.Promotor}</option>
                        )}
                        </select>
                      </FormControl>
                    </Grid>
                </Grid>
            </Typography>
        </DialogContent>
        <DialogActions>
            <Button autoFocus onClick={handleLoadEdit} className={classes.btnaprobar}>
                Guardar
            </Button>
            <Button autoFocus onClick={handleClose} className={classes.btncancelar}>
                Cancelar
            </Button>
        </DialogActions>
      </Dialog>
      <ProgressIntoDialog open={OpenLoad} />
    </div>
  );
}