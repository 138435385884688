import React from 'react';
import { makeStyles} from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import ToolbarDrawer from '../toolbar/ToolbarDrawer';
import DrawerCustom from './DrawerCustom';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  hide: {
    display: 'none',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

export default function MiniDrawer() {
  const classes = useStyles();
  //const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  //const [param, setParam] = React.useState(1);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  /*
  const setOptionView = (view) =>{
    setParam(view);
    handleDrawerClose();
  }*/

  /*
    <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>          
            <ListItem button key={'GestionCitas'} onClick={() => setOptionView(1)}>
              <ListItemIcon>
                <GroupIcon  />
              </ListItemIcon>
              <ListItemText primary={'Gestión de Citas'} />
            </ListItem>            
            <ListItem button key={'Horarios'} onClick={() => setOptionView(2)}>
              <ListItemIcon>
                <HoraryIcon />
              </ListItemIcon>
              <ListItemText primary={'Horarios'} />
            </ListItem>  

            <ListItem button key={'Notificaciones'} onClick={() => setOptionView(3)}>
              <ListItemIcon>
                <NotificationsIcon  />
              </ListItemIcon>
              <ListItemText primary={'Notificaciones'} />
            </ListItem> 

            <ListItem button key={'Promociones'} onClick={() => setOptionView(4)}>
              <ListItemIcon>
                <PromotionsIcon  />
              </ListItemIcon>
              <ListItemText primary={'Promociones'} />
            </ListItem> 
            <ListItem button key={'Historico'} onClick={() => setOptionView(5)}>
              <ListItemIcon>
                <PieChartIcon  />
              </ListItemIcon>
              <ListItemText primary={'Histórico'} />
            </ListItem> 
        </List>
        <Divider />
        <List>          
            <ListItem button key={'Configuraciones'} onClick={() => setOptionView(6)}>
              <ListItemIcon>
                <SettingsIcon   />
              </ListItemIcon>
              <ListItemText primary={'Configuraciones'} />
            </ListItem>            
            <ListItem button key={'AcercaDe'} onClick={() => setOptionView(7)}>
              <ListItemIcon>
                <AboutUsIcon  />
              </ListItemIcon>
              <ListItemText primary={'Nosotros'} />
            </ListItem>
        </List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.toolbar} />
            <OptionView param={param}/>
      </main>
  */

  return (
    <div>
      <CssBaseline />      
      <DrawerCustom open={open} handleDrawerClose={handleDrawerClose} handleDrawerOpen={handleDrawerOpen}/>      
    </div>
  );
}