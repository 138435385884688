import React from 'react';
import moment from 'moment';

export const MainContext = React.createContext();

const MainAppProvider = (props) =>{

    const getDateLocal = () => {       
        return moment().format("YYYY-MM-DD[T]HH:mm:ss");
    }
    const getYearToday = () => {
        return moment().year();
    }
    const getMonthToday = () => {
        return moment().month() + 1;
    }

    return (
        <MainContext.Provider value = {{getDateLocal,getYearToday,getMonthToday}}>
            { props.children } 
        </MainContext.Provider>
    )

}

export default MainAppProvider;