import React from 'react';
import {Bar} from 'react-chartjs-2';

function VerticalBarChart (props) {

    const data = {
        labels: props.arrayElement,
        datasets: [{
            label: `${props.title}`,
            backgroundColor: '#2D8ADE',
            borderColor: 'black',
            borderWidth: 1,
            hoverBackgroundColor: '#A5CDF1',
            hoverBorderColor: '#FFFF00',
            data: props.arrayData//[327,126,60,49,20]
        }]
    }
    const options = {
        maintainAspectRatio: false,
        responsive: true
    }

    return (
        <div style={{width:'100%',height:200}}>
            
            <Bar data={data} options={options} />
        </div>
    )
}

export default VerticalBarChart
