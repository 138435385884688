import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Fab from "@material-ui/core/Fab";
import AddPhotoAlternateIcon from "@material-ui/icons/AddPhotoAlternate";
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import Button from "@material-ui/core/Button";
import CardActionArea from "@material-ui/core/CardActionArea";
import Swal from 'sweetalert2';
import ShowDialog from '../dialogs/ShowDialog';
import Book from "./Book";
import ProgressIntoDialog from '../progress/Progress';
import EditBooks from './EditBook';
import UnauthorizedToken from '../dialogs/UnauthorizedToken';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    }
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);  
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    tabs:{
        color:'black'
    },
    input: {
        display: "none"
    },
    button: {
        color: 'blue',
        margin: 10
    },
    textField: {
        textAlign:'left',
        paddingTop:15,
        width: '50%',
    },
  });

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
  
    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
            <Box p={3}>
                <Typography>{children}</Typography>
            </Box>
            )}
        </div>
        );
    }

export default function NewBook (props){
    const classes = useStyles();  
    const [value, setValue] = React.useState(0);
    const [OneLoading, setOneLoading] = React.useState('');
    const [FechaCarga, setFechaCarga] = React.useState(true);
    const [SelectedFile, setSelectedFile] = React.useState('');
    const [File, setFile] = React.useState('');
    const [MainState, setMainState] = React.useState('Iniciar');
    const [ImageUploaded, setImageUploaded] = React.useState(0);
    const [NuevoCap, setNuevoCap] = React.useState('NuevoCapitulo');
    const [Title, setTitle] = React.useState('');
    const [CodeValidation, setCodeValidation] = React.useState('');
    const [Edition, setEdition] = React.useState('');
    const [DatePublication, setDatePublication] = React.useState('');
    const [CourseId, setCourseId] = React.useState(0);
    const [ColectionId, setColectionId] = React.useState(0);
    const [GradeId, setGradeId] = React.useState(0);
    const [Url, setUrl] = React.useState('');
    const [ListGrades, setListGrades] = React.useState([]);
    const [ListCourses, setListCourses] = React.useState([]);
    const [ListColection, setListColection] = React.useState([]);
    const [show, setShow] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [CancelBook, setCancelBook] = React.useState(false);
    const [loading, setLoading] = React.useState(false);
    const [CodeBook, setCodeBook] = React.useState('');
    const [EditBook, setEditBook] = React.useState(false);
    const [ValidToken, setValidToken] = React.useState(false);

    React.useEffect(()=>{
        GetListCourse();
        GetListGrade();
        GetListColection();
      },[])

    function a11yProps(index) {
        return {
          id: `simple-tab-${index}`,
          'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    if(FechaCarga){
        var now = new Date();
        const FehaDeHoy = now.getDate() + '-' + (now.getMonth() + 1) + '-' + now.getFullYear();
        const FehaOneLoading = now.getFullYear() + '-' + (now.getMonth() + 1) + '-' + now.getDate();
        setOneLoading(FehaOneLoading);
        setFechaCarga(false);
    }

    function handleUploadClick (event)  {
        var file = event.target.files[0];
        const reader = new FileReader();
        var url = reader.readAsDataURL(file);
        setFile(file);
        reader.onloadend = function(e) {
            setSelectedFile([reader.result])
        }.bind(this);

          setMainState('Uploaded');
          setSelectedFile(file);
          setImageUploaded(1);
    };

    function imageResetHandler(event) {
        setMainState('Iniciar');
        setSelectedFile();
        setFile();
        setImageUploaded(0);
    }

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleClose = () => {
        setShow(false);       
    };

    const ShowDialogMessage = (message) =>{
        setShow(true);
        setMessageError(message);
    }

    function handleCancelBook() {
        setCancelBook(true);
    }

    function SaveBook() {
        if(ValidateCampos())
        {
            if(ValidImg())
            {
                UploadImg();
            }
            else
            {
                ShowDialogMessage('Debes seleccionar una imagen.')
            }
        }
        else{
            ShowDialogMessage('Debes llenar los campos correctamente.')
        }
    }

    const ValidateCampos = () =>{
        var valid = true;
        if (Title === "") {
          valid = false;
        }
        if (CodeValidation === "") {
          valid = false;
        }
        if (Edition === "") {
          valid = false;
        }
        if (DatePublication === "") {
          valid = false;
        }
        if (CourseId === 0) {
          valid = false;
        }
        if (GradeId === 0) {
            valid = false;
        }
        if (ColectionId === 0) {
            valid = false;
        }
        else if (!valid){
          valid = false;
        }
        return valid;
    }

    const ValidImg = () => {
        var valid = true;
        if (SelectedFile === "") {
          valid = false;
        }        
        return valid;
    }

    const UploadImg = (event) =>{

        const formDataToUpload = new FormData();
        const files = File;
        const typefile = 3;
        formDataToUpload.append('FileImage', files);
        formDataToUpload.append('TypeFile', typefile);
  
        const URL_BASE = process.env.REACT_APP_URL_BASE_S3;
        const POST_UPLOAD = process.env.REACT_APP_API_FORMADATA_IMAGE;
        const URL = URL_BASE+POST_UPLOAD;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
        try{
            setLoading(true);
            fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    //'Accept':'application/json',
                    //'Content-Type': 'multipart/form-data',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    //'Content-Type': undefined,
                },
                body: formDataToUpload
  
            }).then(function(res){
                if(res.status === 401){
                    return 401;
                }
                return res.json();
            }).then(function(response){
  
                if (response.Code == 0){
                    console.log('response'+ response.Data.PathFile);
                    PostSaveBook(response.Data.PathFile);
                }
                else if(response === 401){
                    setValidToken(true);
                }
                else{
                  showDialogError();
                }           
  
            }).catch((error) =>{
                showDialogError(error);
            })
        }catch(e){
            showDialogError(e);
        }
    }

    const PostSaveBook = (URLIMAGE) =>{        
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let POST_SAVEBOOK = process.env.REACT_APP_API_POST_REGISTERBOOK;
        let URL = URL_BASE+POST_SAVEBOOK;        
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        
        var obj = {
            Titulo: Title,
            CodigoValidacion: CodeValidation,
            Edicion: Edition,
            FechaPublicacion: DatePublication,
            GradoId: parseInt(GradeId),
            CursoId: parseInt(CourseId),
            Url:URLIMAGE,
            ColeccionId: parseInt(ColectionId)
        }
        setLoading(true);
        try{
          fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            },
            body: JSON.stringify(obj)
            }).then(function(res){
                if(res.status === 401){
                    return 401;  
                }
                return res.json();
            }).then(function(response){
                setLoading(false);
  
                if(response.Code === 0){                 
                    if(response.Data.length > 0){  
                        setCodeBook(response.Data);                   
                      AlertLoad();             
                    }else{
                        ShowDialogMessage(response.Message)
                    }
  
                }else if (response.Code === 1){
                        ShowDialogMessage(response.Message);
  
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        ShowDialogMessage(message);                        
                    }
                }
                else if(response === 401){
                    setValidToken(true);
                }
  
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    const showErrorMessage = (msg) => {
        /*if (showing){
            return;
        }
  
        setShow(true);
        setShowing(true);
        setMessageError(msg);
    
        setTimeout(() => {
            setShow(false);
            setShowing(false);
            setMessageError('');            
        }, 4000);*/
    }

    const showDialogError = (message) =>{        
        console.log(message);
    }

    function AlertLoad(){
        Swal.fire(
          'Hecho',
          'Archivo Guardado Satisfactoriamente.',
          'success'
        ).then((result) => {
            if (result.isConfirmed) {
                setEditBook(true);
            }
        })
    }

    const handleFilterTitle = (e) =>{
        setTitle(e.target.value.toString());
    }

    const handleFilterCodeValidation= (e) =>{
        setCodeValidation(e.target.value.toString());
    }

    const handleFilterEdition= (e) =>{
        setEdition(e.target.value.toString());
    }

    function formato(texto){
        return texto.replace(/^(\d{4})-(\d{2})-(\d{2})$/g,'$3/$2/$1');
    }

    const handleFilterDatePublication= (e) =>{
        var fechachange = e.target.value;
        var formtfecha = formato(fechachange);
        var DPublication = formtfecha.split('/').join('-');
        setDatePublication(DPublication);
    }

    const handleChangeCourse = (event) => {
        setCourseId(event.target.value);
    }

    const handleChangeColection = (event) => {
        setColectionId(event.target.value);
    }
  
    const handleChangeGrade = (event) => {
        setGradeId(event.target.value);
    }
    

    const GetListGrade = () =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_GRADE = process.env.REACT_APP_API_GET_GRADE;
        let URL = URL_BASE+GET_GRADE;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
                if(res.status === 401){
                    return 401;  
                }
                return res.json();
            }).then(function(response){
    
                if(response.Code === 0){
                    setListGrades(response.Data)
    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
                else if(response === 401){
                    setValidToken(true);
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    const GetListCourse = () =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_COURSE = process.env.REACT_APP_API_GET_COURSES;
        let URL = URL_BASE+GET_COURSE;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
                if(res.status === 401){
                    return 401;  
                }
                return res.json();
            }).then(function(response){
    
                if(response.Code === 0){
                    setListCourses(response.Data)
    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
                else if(response === 401){
                    setValidToken(true);
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    const GetListColection = () =>{
        const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
        let GET_COLECTION = process.env.REACT_APP_API_GET_LIST_COLECTION;
        let URL = URL_BASE+GET_COLECTION;
        const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
        try{
            fetch(URL,{
                method:'get',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'Authorization': 'Bearer ' + tokenLocalStorage,
                    'Content-type': 'application/json'
                }                
            }).then(function(res){
                if(res.status === 401){
                    return 401;  
                }
                return res.json();
            }).then(function(response){
    
                if(response.Code === 0){
                    setListColection(response.Data)
    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element}.`
                        });
                        showErrorMessage(message);                        
                    }
    
                }else if (response.Code === 2){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        showErrorMessage(message);                        
                    }
                }
                else if(response === 401){
                    setValidToken(true);
                }
    
            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    function BorrarUno(boton) {
        console.log("entro");
        console.log(boton.parentNode);
        var borrardiv = boton.parentNode;
        document.getElementById("idcapitulos").removeChild(borrardiv);
    }

    function crear() {
        var creardiv = document.createElement("div");
        creardiv.style.paddingBottom = "20px";
        creardiv.innerHTML = NuevoCap + "<button onClick={BorrarUno}>Eliminar</button>";
        document.getElementById("idcapitulos").appendChild(creardiv);
    }

    if(CancelBook)
    {
        return <Book/>
    }

    if(EditBook)
    {
        return <EditBooks id={CodeBook}/>
    }

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Nuevo Libro</h1>
              </Grid>
            </Grid>
            <AppBar position="static">
                <Tabs value={value} onChange={handleChange} aria-label="simple tabs example">
                <Tab label="Datos Generales" {...a11yProps(0)} className={classes.tabs}/>
                <Tab label="Esquema" {...a11yProps(1)} className={classes.tabs} disabled/>
                </Tabs>
            </AppBar>
            <TabPanel value={value} index={0}>
                <Grid container>
                    {
                        MainState == 'Iniciar' && 
                        <Grid item xs={6} style={{paddingRight:'3%'}}>
                            <div>
                            <input
                                accept="image/*"
                                id="contained-button-file"
                                multiple
                                type="file"
                                style={{display:'none'}}
                                onChange={handleUploadClick}
                            />
                            <label htmlFor="contained-button-file" style={{position:'initial', pointerEvents:'all'}}>
                                <Fab component="span" className={classes.button}>
                                    <AddPhotoAlternateIcon/>
                                </Fab>
                            </label>
                            </div>
                        </Grid>
                    }
                    {
                        MainState == 'Uploaded' && 
                        <Grid item xs={6} style={{paddingRight:'3%'}}>
                            <CardActionArea onClick={imageResetHandler}>
                            <img
                                width="100%"
                                src={SelectedFile}
                            />
                            </CardActionArea>
                        </Grid>
                    }
                    <Grid item xs={6}>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Título</a>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterTitle}/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Código Validación</a>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterCodeValidation}/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Edición</a>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterEdition}/>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Fecha De Publicación</a>
                            </Grid>
                            <Grid item xs={8}>
                                <TextField
                                    id="date"
                                    label=""
                                    type="date"
                                    defaultValue={OneLoading}
                                    className={classes.textField}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleFilterDatePublication}
                                />
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Grado</a>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                                    <select name="GradoId" id="GradoId" className="form-control" value={GradeId} onChange={handleChangeGrade}>
                                    <option value="0">- Seleccione Grado -</option>
                                    {
                                        ListGrades.map((element,key) =>
                                        <option value={element.IdGradoInstruccion}>{element.NombreGrado}</option>
                                    )} 
                                    </select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Curso</a>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                                    <select name="CursoId" id="CursoId" className="form-control" value={CourseId} onChange={handleChangeCourse}>
                                    <option value="0">- Seleccione Curso -</option>
                                    {
                                        ListCourses.map((element,key) =>
                                        <option value={element.IdCursoLibro}>{element.NombreCurso}</option>
                                    )}   
                                    </select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container>
                            <Grid item xs={3} style={{paddingTop:15, textAlign:'left'}} >
                                <a style={{fontSize:18}}>Colección</a>
                            </Grid>
                            <Grid item xs={8}>
                                <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                                    <select name="ColeccionId" id="ColeccionId" className="form-control" value={ColectionId} onChange={handleChangeColection}>
                                    <option value="0">- Seleccione Colección -</option>
                                    {
                                        ListColection.map((element,key) =>
                                        <option value={element.ColeccionId}>{element.NombreColeccion}</option>
                                    )}   
                                    </select>
                                </FormControl>
                            </Grid>
                        </Grid>
                        <Grid container style={{paddingTop:20}}>
                            <Grid item xs={3}>
                                <Button variant="contained" className="btn-consultar" onClick={SaveBook}>
                                    Guardar
                                </Button>
                            </Grid>
                            <Grid item xs={1}></Grid>
                            <Grid item xs={3}>
                                <Button variant="contained" className="btn-nuevo" onClick={handleCancelBook}>
                                    Cancelar
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </TabPanel>
            <TabPanel value={value} index={1}>
                <Grid container>
                    Crear Capitulos
                    <button type="button" onClick={crear}>AGREGAR</button>
                </Grid>
                <Grid item xs={4} id="idcapitulos" style={{textAlign:'left'}}>
                </Grid>
            </TabPanel>
            <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
            <ProgressIntoDialog open={loading} />
            <UnauthorizedToken open={ValidToken} />
        </div>
    )
}