import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import UnauthorizedToken from '../dialogs/UnauthorizedToken';
import CancelIcon from '@material-ui/icons/Cancel';
import CheckIcon from '@material-ui/icons/Check';
//import NuevoUsuario from './NewUsuario';
//import EditUsuario from './EditUsuario';
//import ResetPassword from "../dialogs/ResetPassword";

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
  });

export default function Students (props){
    const classes = useStyles();    
    const [param, setParam] = React.useState(4);
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [ListUser, setListUser] = React.useState([]);
    const [IdEdit, setIdEdit] = React.useState('');
    const [NameCall, setNameCall] = React.useState('');
    const [UserCodeDelete, setUserCodeDelete] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [StatusDelete, setStatusDelete] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [Name, setName] = React.useState('-');
    const [UserType, setUserType] = React.useState(0);
    const [openResetPassword, setOpenResetPassword] = React.useState(false);
    const [CodeUserReset, setCodeUserReset] = React.useState('');
    const [ListColegio, setListColegio] = React.useState([]);
    const [ListProvince, setListProvince] = React.useState([]);
    const [ListDepartment, setListDepartment] = React.useState([]);
    const [ListDistrict, setListDistrict] = React.useState([]);
    const [DepartmentId, setDepartmentId] = React.useState('0');
    const [ProvinceId, setProvinceId] = React.useState('0');
    const [DistrictId, setDistrictId] = React.useState('0');
    const [StatusId, setStatusId] = React.useState('0');
    const [SchoolId, setSchoolId] = React.useState(0);
    const [Dni, setDni] = React.useState('-');
    const [UserCode, setUserCode] = React.useState('');
    const [StatusDesactivate, setStatusDesactivate] = React.useState(false);
    const [StatusActivate, setStatusActivate] = React.useState(false);
    const [ValidToken, setValidToken] = React.useState(false);

    React.useEffect(()=>{
      GetListColegio();
      GetListDepartamento();
      GetListTeachers();
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    function handleClick() {
      //setNewRegister(true);
      GetDowlandTeachers();
    }

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    const onChangeSchool = (event) =>{
      var text = event.target.value;
      setSchoolId(parseInt(text));
    }

    const onChangeDepartament = (event) =>{
      var text = event.target.value;
      setDepartmentId(text);
      setProvinceId('0');
      setDistrictId('0');
      GetListProvince(parseInt(text));
    }

    const onChangeProvince = (event) =>{
      var text = event.target.value;
      setProvinceId(text);
      setDistrictId('0');
      GetListDistrict(text);
    }

    const onChangeStatus = (event) =>{
      var text = event.target.value;
      setStatusId(text);
    }

    const onChangeDistrict = (event) =>{
      var text = event.target.value;
      setDistrictId(text);
    }

    function handleClickSweet() {
      GetListTeachers();
    }

    const handleClickEdit = (e) =>{
      console.log(e.currentTarget.id);
      setIdEdit(e.currentTarget.id);
      setStatusDelete(false);
      setEditRegister(true);
    }

    const handleClickDelete = (e) =>{
      setUserCodeDelete(e.currentTarget.id);
      setStatusDelete(true);
    }

    const handleClickReset = (e) =>{
      setCodeUserReset(e.currentTarget.id);
      setOpenResetPassword(true);
    }

    const handleCloseResetPassword = () => {
      setOpenResetPassword(false);   
    };

    const handleFilterName = (e) =>{
      if(e.target.value.toString() == "")
      {
        setName('-');
      }
      else{
        setName(e.target.value.toString());
      }
    }

    const handleFilterDni = (e) =>{
      if(e.target.value.toString() == "")
      {
        setDni('-');
      }
      else{
        setDni(e.target.value.toString());
      }
    }

    const handleClickDesactivate = (e) =>{
      setUserCode(e.currentTarget.id);
      setStatusDesactivate(true);
    }

    const handleClickActivate = (e) =>{
      setUserCode(e.currentTarget.id);
      setStatusActivate(true);
    }

    const handleChangeUserType = (event) =>{
      setUserType(event.target.value);
    }
    

    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
  }

  if(StatusActivate){
    ActivateConfirm();
  }
  function ActivateConfirm(){
    if(UserCode.length > 0){
      Swal.fire({
        title: 'Activar',
        text: "¿Esta seguro que desea Activar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          PostActivateUser();
          setStatusActivate(false);
        }
      })
     }
  }

  if(StatusDesactivate){
    DesactivateConfirm();
  }
  function DesactivateConfirm(){
    if(UserCode.length > 0){
      Swal.fire({
       title: 'Desactivar',
        text: "¿Esta seguro que desea Desactivar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Aceptar',
        cancelButtonText: 'Cancelar'
      }).then((result) => {
        if (result.isConfirmed) {
          PostDesactivateUser();
          setStatusDesactivate(false);
        }
      })
     }
  }

  const PostActivateUser = () =>{        
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let POST_SAVE_USER_TEACHER = process.env.REACT_APP_API_POST_ACTIVATETEACHER;
    let URL = URL_BASE+POST_SAVE_USER_TEACHER;        
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    var obj = {
      Code: UserCode
    }
    try{
      fetch(URL,{
        method:'post',
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }).then(function(res){
            if(res.status === 401){
              return 401;  
            }
            return res.json();
        }).then(function(response){
            if(response.Code === 0){                    
                if(response.Data){  
                  GetListTeachers();                     
                  setStatusActivate(false);  
                  Swal.fire(
                    'Activado',
                    'Se Activó Correctamente.',
                    'success'
                  )          
                }else{
                    ShowDialogMessage(response.Data.Message)
                }

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const PostDesactivateUser = () =>{        
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let POST_SAVE_USER_TEACHER = process.env.REACT_APP_API_POST_DESACTIVATTEACHER;
    let URL = URL_BASE+POST_SAVE_USER_TEACHER;        
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    var obj = {
      Code: UserCode
    }
    try{
      fetch(URL,{
        method:'post',
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }).then(function(res){
            if(res.status === 401){
              return 401;  
            }
            return res.json();
        }).then(function(response){
            if(response.Code === 0){                    
                if(response.Data){  
                  GetListTeachers();                     
                  setStatusDesactivate(false);  
                  Swal.fire(
                    'Desactivado',
                    'Se Desactivó Correctamente.',
                    'success'
                  )          
                }else{
                    ShowDialogMessage(response.Data.Message)
                }

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListTeachers = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_TEACHERS = process.env.REACT_APP_API_GET_LIST_TEACHERS;
    let URL = URL_BASE+GET_TEACHERS+SchoolId+'/'+DepartmentId+'/'+ProvinceId+'/'+DistrictId+'/'+Dni+'/'+Name+'/'+StatusId;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){
            if(res.status === 401){
              return 401;  
            }
            return res.json();
        }).then(function(response){

            if(response.Code === 0){
                console.log(response.Data);
                setListUser(response.Data);

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListColegio = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_User = process.env.REACT_APP_API_GET_LIST_SCHOOL;
    let URL = URL_BASE+GET_User;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){
            if(res.status === 401){
              return 401;  
            }
            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListColegio(response.Data.ListaColegios)
            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListDepartamento = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_SEGURIDAD;
    let GET_DEPARTMENT = process.env.REACT_APP_API_GET_LIST_DEPARTMENT;
    let Key = process.env.REACT_APP_API_KEY_SEGURIDAD;
    let URL = URL_BASE+GET_DEPARTMENT;
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                //'Authorization': 'Bearer ' + tokenLocalStorage,
                'ApiKey': Key,
                'Content-type': 'application/json'
            }                
        }).then(function(res){

            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListDepartment(response.Data.ListaUbiDepartamentos)
            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListProvince = (id) =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_SEGURIDAD;
    let GET_PROVINCE = process.env.REACT_APP_API_GET_LIST_PROVINCE;
    let Key = process.env.REACT_APP_API_KEY_SEGURIDAD;
    let URL = URL_BASE+GET_PROVINCE+'/'+id;
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                //'Authorization': 'Bearer ' + tokenLocalStorage,
                'ApiKey': Key,
                'Content-type': 'application/json'
            }                
        }).then(function(res){

            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListProvince(response.Data.ListaUbiProvincias);
            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListDistrict = (id) =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_SEGURIDAD;
    let GET_DISTRICT = process.env.REACT_APP_API_GET_LIST_DISTRICT;
    let Key = process.env.REACT_APP_API_KEY_SEGURIDAD;
    let URL = URL_BASE+GET_DISTRICT+'/'+id;
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                //'Authorization': 'Bearer ' + tokenLocalStorage,
                'ApiKey': Key,
                'Content-type': 'application/json'
            }                
        }).then(function(res){

            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListDistrict(response.Data.ListaUbiDistritos);
            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetDowlandTeachers = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_Archive = process.env.REACT_APP_API_GET_EXPORT_TEACHERS;
    let URL = URL_BASE+GET_Archive+SchoolId+'/'+DepartmentId+'/'+ProvinceId+'/'+DistrictId+'/'+Dni+'/'+Name;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
      fetch(URL, {
          method: 'get',
          headers: {
              'content-type': 'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              //'X-CSRF-TOKEN': getCookies("CSRF-TOKEN")
          }
          }).then( function (res){
            return res.blob();

          }).then( function (response){

            let url = window.URL.createObjectURL(response);
            let a = document.createElement('a');
            a.href = url;
            a.download = 'ConsultaDocentes.xlsx';
            a.click();

            }).catch(error =>
            {
                console.log(error)
            });
    }catch(e){
        console.log(e);
    }
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Consulta de Docentes</h1>
              </Grid>
            </Grid>
            <div>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left' }} >
                    <a style={{fontSize:18}}>Colegio</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="ColegioId" id="ColegioId" className="form-control" onChange={onChangeSchool}>
                      <option value="0">- Seleccione Colegio -</option>
                      {
                      ListColegio.map((element,key) =>
                      <option value={element.ColegioId}>{element.NombreColegio}</option>
                      )}
                      </select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, paddingLeft:'2%', textAlign:'left'}} >
                    <a style={{fontSize:18}}>Departamentos</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="DepartamentId" id="DepartamentId" className="form-control" onChange={onChangeDepartament}>
                      <option key='24' value="0">- Seleccione Departamento -</option>
                      {
                      ListDepartment.map((element,key) =>
                      <option key={element.DepartamentoCod} value={element.DepartamentoCod}>{element.NombreDepartamento}</option>
                      )}
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container style={{marginTop:30}} >
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                  <a style={{fontSize:18}}>Provincia</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="ProvinceId" id="ProvinceId" className="form-control" onChange={onChangeProvince}>
                      <option key='24' value="0">- Seleccione Provincia -</option>
                      {
                      ListProvince.map((element,key) =>
                      <option key={element.ProvinciaCod} value={element.ProvinciaCod}>{element.NombreProvincia}</option>
                      )}
                      </select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, paddingLeft:'2%', textAlign:'left'}} >
                    <a style={{fontSize:18}}>Distrito</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="DiscrictId" id="DiscrictId" className="form-control" onChange={onChangeDistrict}>
                      <option key='24' value="0">- Seleccione Distrito -</option>
                      {
                      ListDistrict.map((element,key) =>
                      <option key={element.DistritoCod} value={element.DistritoCod}>{element.NombreDistrito}</option>
                      )}
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Dni</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterDni}/>                    
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, paddingLeft:'2%', textAlign:'left'}} >
                  <a style={{fontSize:18}}>Nombre Y Apellidos</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterName}/>
                </Grid>
              </Grid>
            </div>
            <div>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Estado</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="EstadoId" id="EstadoId" className="form-control" onChange={onChangeStatus}>
                      <option value="0">- Seleccione Estado -</option>
                      <option value="A">Activo</option>
                      <option value="I">Inactivo</option>
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
            </div>
            <div>
            <Grid container style={{marginTop:30}} >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={handleClickSweet}>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left'}}>
                <Button variant="contained" className="btn-nuevo" onClick={handleClick}>
                  Exportar
                </Button>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
            <Grid item xs={10} >
              <TableContainer component={Paper}></TableContainer>
              <Table className={classes.table, 'table-borde'} aria-label="customized table">
              <TableHead>
              <TableRow style={{border:'2px solid #dee2e6'}}>
                  <StyledTableCell>Acciones</StyledTableCell>
                  <StyledTableCell>Dni</StyledTableCell>
                  <StyledTableCell align="left">Nombre Y Apellidos</StyledTableCell>
                  <StyledTableCell align="left">Colegio</StyledTableCell>
                  <StyledTableCell align="left">Promotor</StyledTableCell>
                  <StyledTableCell align="left">Correo</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {
                  ListUser.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key) =>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="table-border-right">
                    {
                      element.Estado == "A"? (
                        <Button id={element.Code} onClick={(e) => handleClickDesactivate(e)}>
                          <CancelIcon/>
                        </Button>
                      ):( <Button id={element.Code} onClick={(e) => handleClickActivate(e)}>
                        <CheckIcon/>
                      </Button> )                       
                    } 
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">
                        {element.NumDoc}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">
                      {element.Nombres} {element.Apellidos}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreColegio}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Promotor}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Email}</StyledTableCell>
                  
                  </StyledTableRow>
                )}
                {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={6} style={{display:'none'}}/>
                </TableRow>
                )}
              </TableBody>              
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={ListUser.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={1} >
            </Grid>
            </Grid>
            <UnauthorizedToken open={ValidToken} />
            </div>
        </div>
    )
}