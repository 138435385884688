import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import DeleteIcon from '@material-ui/icons/Delete';
import Label from '@material-ui/icons/Label';
import SupervisorAccountIcon from '@material-ui/icons/SupervisorAccount';
import InfoIcon from '@material-ui/icons/Info';
import ForumIcon from '@material-ui/icons/Forum';
import LocalOfferIcon from '@material-ui/icons/LocalOffer';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import BookIcon from '@material-ui/icons/Book';
import BuildIcon from '@material-ui/icons/Build';
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter';
import SchoolIcon from '@material-ui/icons/School';
import PermIdentityIcon from '@material-ui/icons/PermIdentity';
import QueryBuilderIcon from '@material-ui/icons/QueryBuilder';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssessmentIcon from '@material-ui/icons/Assessment';
import PersonIcon from '@material-ui/icons/Person';
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew';
import CollectionsBookmarkIcon from '@material-ui/icons/CollectionsBookmark';
import CollectionsIcon from '@material-ui/icons/Collections';

const useTreeItemStyles = makeStyles((theme) => ({
    /*
  root: {
    color: theme.palette.text.secondary,
    '&:hover > $content': {
      backgroundColor: theme.palette.action.hover,
    },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `var(--tree-view-bg-color, ${theme.palette.grey[400]})`,
      color: 'var(--tree-view-color)',
    },
    '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
      backgroundColor: 'transparent',
    },
  },*/
  content: {
    //color: theme.palette.text.primary,
    color:'white',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
    textAlign: 'left',
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const { labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, onClick, ...other } = props;

  return (
    <TreeItem
      label={
        <div className={classes.labelRoot} onClick={onClick}>
          <LabelIcon color="inherit" className={classes.labelIcon} />
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

const useStyles = makeStyles({
  root: {
    height: 264,
    flexGrow: 1,
    maxWidth: 400,
    background:'transparent'
  },
});

export default function TreeMenu(props) {
    const classes = useStyles();

    const {selectedBookManagement,
        selectedSchoolMaintenance,
        selectedUsersMaintenance,
        selectedStudentConsult,
        selectedTeacherConsult,
        selectedActivateConsult,
        selectedReportDashboard,
        selectedPromoterMaintenance,
        selectedCollectionMaintenance } = props;
        
    const handleBook = () =>{
        console.log("book")
    }

    return (
        <TreeView
        className={classes.root}
        //defaultExpanded={['1']}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
        >
            <StyledTreeItem nodeId="1" labelText="Gestión" labelIcon={CollectionsBookmarkIcon}  >
                <div style={{backgroundColor:"#4b545d",borderRadius:5}}>
                    <StyledTreeItem
                    nodeId="11"
                    labelText="Libros"
                    labelIcon={BookIcon}
                    //labelInfo="90"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={selectedBookManagement}
                    />
                </div>
            </StyledTreeItem>
        
            

            <StyledTreeItem nodeId="2" labelText="Mantenimientos" labelIcon={BuildIcon} >
                <div style={{backgroundColor:"#4b545d",borderRadius:5}}>
                    <StyledTreeItem
                    nodeId="8"
                    labelText="Promotor"
                    labelIcon={BusinessCenterIcon}
                    //labelInfo="90"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={selectedPromoterMaintenance}
                    />
                    <StyledTreeItem
                    nodeId="9"
                    labelText="Colegios"
                    labelIcon={SchoolIcon}
                    //labelInfo="2,294"
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={selectedSchoolMaintenance}
                    />
                    <StyledTreeItem
                    nodeId="10"
                    labelText="Usuarios"
                    labelIcon={PermIdentityIcon}
                    //labelInfo="3,566"
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={selectedUsersMaintenance}
                    />
                    <StyledTreeItem
                    nodeId="100"
                    labelText="Coleccion"
                    labelIcon={CollectionsIcon}
                    //labelInfo="3,566"
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={selectedCollectionMaintenance}
                    />
                </div>                
            </StyledTreeItem>
                
           

            <StyledTreeItem nodeId="3" labelText="Consultas" labelIcon={QueryBuilderIcon}>
                <div style={{backgroundColor:"#4b545d",borderRadius:5}}>
                    <StyledTreeItem
                    nodeId="5"
                    labelText="Alumnos"
                    labelIcon={SchoolIcon}
                    //labelInfo="90"
                    color="#1a73e8"
                    bgColor="#e8f0fe"
                    onClick={selectedStudentConsult}
                    />
                    <StyledTreeItem
                    nodeId="6"
                    labelText="Docentes"
                    labelIcon={PersonIcon}
                    //labelInfo="2,294"
                    color="#e3742f"
                    bgColor="#fcefe3"
                    onClick={selectedTeacherConsult}
                    />
                    <StyledTreeItem
                    nodeId="7"
                    labelText="Activación"
                    labelIcon={PowerSettingsNewIcon}
                    //labelInfo="3,566"
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={selectedActivateConsult}
                    />
                </div>
            </StyledTreeItem>
            
            

            <StyledTreeItem nodeId="4" labelText="Reportes" labelIcon={AssessmentIcon} >
                <div style={{backgroundColor:"#4b545d",borderRadius:5}}>
                    <StyledTreeItem
                    nodeId="12"
                    labelText="Dashboard"
                    labelIcon={DashboardIcon}
                    //labelInfo="3,566"
                    color="#a250f5"
                    bgColor="#f3e8fd"
                    onClick={selectedReportDashboard}
                    />
                </div>            
            </StyledTreeItem>

            

        </TreeView>
    );
}
