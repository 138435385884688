import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import EditConexionModel from '../dialogs/EditConexionModal';
import NewConexionModel from '../dialogs/NewConexionModal';

const StyledTableCell = withStyles((theme) => ({
	head: {
		backgroundColor: '#ffff',
		color: 'black',
		borderBottom: '3px solid #dee2e6',
		borderRight: '2px solid #dee2e6',
		fontWeight: 600,
	},
	body: {
		fontSize: 14,
	},
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
	root: {
		'&:nth-of-type(odd)': {
			backgroundColor: theme.palette.action.hover,
		},
	},
}))(TableRow);

function createData(name, calories, fat, carbs) {
	return { name, calories, fat, carbs };
}

const rows = [createData('1', 'Edwin', '561615616', 'Activo')];

const useStyles = makeStyles({
	table: {
		minWidth: 500,
	},
});

export default function Conexion(props) {
	const classes = useStyles();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(5);
	const emptyRows =
		rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);
	const [NombreConexion, setNombreConexion] = React.useState('-');
	const [ListConexion, setListConexion] = React.useState([]);
	const [openPromoter, setOpenNewPromoter] = React.useState(false);
	const [IdEdit, setIdEdit] = React.useState(0);
	const [StatusDelete, setStatusDelete] = React.useState(false);
	const [openEditPromoter, setopenEditPromoter] = React.useState(false);
	const [IdPromoter, setIdPromoter] = React.useState(0);
	const [show, setShow] = React.useState(false);
	const [messageError, setMessageError] = React.useState('');

	React.useEffect(() => {
		GetListConexion();
	}, []);

	const handleFilterTitle = (e) => {
		if (e.target.value.toString() == '') {
			setNombreConexion('-');
		} else {
			setNombreConexion(e.target.value.toString());
		}
	};

	function ClickConsultar() {
		GetListConexion();
	}

	const EditSchoolName = () => {
		GetListConexion();
		setopenEditPromoter(false);
		setIdEdit(0);
	};

	const showErrorMessage = (msg) => {
		console.log(msg);
	};

	const GetListConexion = () => {
		const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
		let GET_SCHOOL = process.env.REACT_APP_API_GET_CONEXION;
		let URL = URL_BASE + GET_SCHOOL + NombreConexion;
		const tokenLocalStorage = localStorage.getItem(
			process.env.REACT_APP_KEY_TOKEN
		);
		try {
			fetch(URL, {
				method: 'get',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					Authorization: 'Bearer ' + tokenLocalStorage,
					'Content-type': 'application/json',
				},
			})
				.then(function (res) {
					return res.json();
				})
				.then(function (response) {
					if (response.Code === 0) {
						setListConexion(response.Data);
					} else if (response.Code === 1) {
						var message = '';
						if (response.Data.Errors.length > 0) {
							response.Data.Errors.forEach((element) => {
								message = `${element}.`;
							});
							showErrorMessage(message);
						}
					} else if (response.Code === 2) {
						var message = '';
						if (response.Data.Errors.length > 0) {
							response.Data.Errors.forEach((element) => {
								message = `${element.FieldName}. ${element.Message}. `;
							});
							showErrorMessage(message);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (e) {
			console.log(e);
		}
	};

	const NewPromoterName = () => {
		setOpenNewPromoter(true);
	};

	const handleClickEdit = (e) => {
		setOpenNewPromoter(false);
		setIdEdit(e.currentTarget.id);
		setStatusDelete(false);
		setopenEditPromoter(true);
	};

	const handleClickDelete = (e) => {
		setIdPromoter(e.currentTarget.id);
		setStatusDelete(true);
	};

	if (StatusDelete) {
		DeleteConfirm();
	}

	function DeleteConfirm() {
		if (IdPromoter.length > 0) {
			Swal.fire({
				title: 'Eliminar',
				text: '¿Esta seguro que desea Eliminar?',
				icon: 'warning',
				showCancelButton: true,
				confirmButtonColor: '#3085d6',
				cancelButtonColor: '#d33',
				confirmButtonText: 'Aceptar',
				cancelButtonText: 'Cancelar'

			}).then((result) => {
				console.log(result);
				if (result.isConfirmed) {
					PostDeletePromoter();
				} else {
					setStatusDelete(false);
				}
			});
		}
	}

	const PostDeletePromoter = () => {
		const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
		let POST_DELETESCHOOL = process.env.REACT_APP_API_POST_DELETECONEXION;
		let URL = URL_BASE + POST_DELETESCHOOL;
		const tokenLocalStorage = localStorage.getItem(
			process.env.REACT_APP_KEY_TOKEN
		);
		var obj = {
			IdColeccion: parseInt(IdPromoter),
		};
		try {
			fetch(URL, {
				method: 'post',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					Authorization: 'Bearer ' + tokenLocalStorage,
					'Content-type': 'application/json',
				},
				body: JSON.stringify(obj),
			})
				.then(function (res) {
					return res.json();
				})
				.then(function (response) {
					if (response.Code === 0) {
						GetListConexion();
						setStatusDelete(false);
						Swal.fire(
							'Eliminado',
							'Se Eliminó Correctamente.',
							'success'
						);
					} else if (response.Code === 1) {
						var message = '';
						if (response.Data.Errors.length > 0) {
							response.Data.Errors.forEach((element) => {
								message = `${element.FieldName}. ${element.Message}. `;
							});
							ShowDialogMessage(message);
						}
					} else if (response.Code === 2) {
						var message = '';
						if (response.Data.Errors.length > 0) {
							response.Data.Errors.forEach((element) => {
								message = `${element.FieldName}. ${element.Message}. `;
							});
							ShowDialogMessage(message);
						}
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (e) {
			console.log(e);
		}
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleClosePromoter = () => {
		setOpenNewPromoter(false);
		GetListConexion();
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const EditPromoterName = () => {
		GetListConexion();
		setopenEditPromoter(false);
		setIdEdit(0);
	};

	const ShowDialogMessage = (message) => {
		setShow(true);
		setMessageError(message);
	};

	return (
		<div style={{ marginLeft: 50, marginRight: 30, backgroundColor: '#fff' }}>
			<Grid container style={{ marginTop: 20 }}>
				<Grid
					item
					xs={12}
					style={{
						borderTop: 3,
						borderTopColor: '#919295',
						borderTopStyle: 'solid',
						borderBottomWidth: 2,
						borderBottomColor: '#919295',
						borderBottomStyle: 'solid',
					}}
				>
					<h1 style={{ margin: 5, fontSize: '18pt' }}>
						Mantenedor de Coleccion
					</h1>
				</Grid>
			</Grid>

			<div style={{ paddingBottom: 20 }}>
				<Grid container style={{ marginTop: 30 }}>
					<Grid item xs={1}></Grid>

					<Grid item xs={2} style={{ paddingTop: 15, textAlign: 'left' }}>
						<a style={{ fontSize: 16 }}>Nombre de coleccion</a>
					</Grid>

					<Grid item xs={3}>
						<TextField
							id="outlined-margin-dense"
							className={classes.formControl}
							margin="dense"
							label=""
							variant="outlined"
							style={{ width: '100%' }}
							onChange={handleFilterTitle}
						/>
					</Grid>
				</Grid>
			</div>

			<div>
				<Grid container>
					<Grid item xs={1}></Grid>
					<Grid
						item
						xs={1}
						style={{ paddingBottom: 10, textAlign: 'left', paddingRight: 5 }}
					>
						<Button
							variant="contained"
							className="btn-consultar"
							onClick={ClickConsultar}
						>
							Consultar
						</Button>
					</Grid>
					<Grid item xs={1} style={{ paddingBottom: 10, textAlign: 'left' }}>
						<Button
							variant="contained"
							className="btn-nuevo"
							onClick={NewPromoterName}
						>
							Nuevo
						</Button>
					</Grid>
				</Grid>

				<Grid container>
					<Grid item xs={1}></Grid>
					<Grid item xs={10}>
						<TableContainer component={Paper}></TableContainer>
						<Table
							className={(classes.table, 'table-borde')}
							aria-label="customized table"
						>
							<TableHead>
								<TableRow style={{ border: '2px solid #dee2e6' }}>
									<StyledTableCell>Acciones</StyledTableCell>

									<StyledTableCell align="left">
										NOMBRE COLECCION
									</StyledTableCell>
									<StyledTableCell align="left">ESTADO</StyledTableCell>
								</TableRow>
							</TableHead>

							<TableBody>
								{ListConexion.slice(
									page * rowsPerPage,
									page * rowsPerPage + rowsPerPage
								).map((element, key) => (
									<StyledTableRow>
										<StyledTableCell
											align="left"
											className="table-border-right"
										>
											<Grid item>
												<Button
													id={element.Idcoleccion}
													onClick={(e) => handleClickEdit(e)}
													style={{ minWidth: 40 }}
												>
													<BorderColorSharpIcon />
												</Button>
												<Button
													id={element.Idcoleccion}
													onClick={(e) => handleClickDelete(e)}
													style={{ minWidth: 40 }}
												>
													<DeleteOutlinedIcon />
												</Button>
											</Grid>
										</StyledTableCell>
										<StyledTableCell
											align="left"
											style={{ display: 'none' }}
											className="table-border-right"
										>
											{element.Idcoleccion}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											className="table-border-right"
										>
											{element.Nombre}
										</StyledTableCell>
										<StyledTableCell
											align="left"
											className="table-border-right"
										>
											{element.Estado}
										</StyledTableCell>
									</StyledTableRow>
								))}
								{emptyRows > 0 && (
									<TableRow style={{ height: 1 * emptyRows }}>
										<TableCell colSpan={6} style={{ display: 'none' }} />
									</TableRow>
								)}
							</TableBody>
						</Table>
						<TablePagination
							rowsPerPageOptions={[5, 10, 15]}
							component="div"
							count={ListConexion.length}
							rowsPerPage={rowsPerPage}
							page={page}
							onChangePage={handleChangePage}
							onChangeRowsPerPage={handleChangeRowsPerPage}
						/>
					</Grid>
					<Grid item xs={1}></Grid>
				</Grid>
				<EditConexionModel
					open={openEditPromoter}
					handleClose={EditSchoolName}
					id={IdEdit}
				/>
				<NewConexionModel
					open={openPromoter}
					handleClose={handleClosePromoter}
				/>
			</div>
		</div>
	);
}
