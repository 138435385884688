import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import AdminUsuario from './AdminUsuario';
import ProgressIntoDialog from '../progress/Progress';
import ShowDialog from '../dialogs/ShowDialog';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#00704a',
      color: theme.palette.common.white,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const  useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
    btncancelar: {
      color: '#444 !important',
      backgroundColor: '#f8f9fa !important',
      border: '1px solid #ddd',
      boxShadow: 'none',
      textTransform: 'none !important',
      width: '50%',
      textAlign: 'left',
      marginLeft: 20,
    },
  });

export default function NewUsuario (props){
    const classes = useStyles();    
    const [param, setParam] = React.useState(4);
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [ListUser, setListUser] = React.useState([]);
    const [loading, setLoading] = React.useState(false);
    const [NameCall, setNameCall] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [UserType, setUserType] = React.useState(0);
    const [Name, setName] = React.useState('');
    const [LastName, setLastName] = React.useState('');
    const [Email, setEmail] = React.useState('');
    const [UserName, setUserName] = React.useState('');
    const [Password, setPassword] = React.useState('');
    const [Document, setDocument] = React.useState('');
    const [RepeatPassword, setRepeatPassword] = React.useState('');
    const [GoMessageFinal, setGoMessageFinal] = React.useState(false);
    
    React.useEffect(()=>{
      //GetListUser();
    },[])

    function handleClick() {
      //setNewRegister(true);
      setGoMessageFinal(true);
    }

    const handleClose = () => {
      setShow(false);       
    };

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    function handleClickSweet() {
      console.log('click');
      if (ValidateCampos()) {
        if (ValidatePassword()) {
          if(validateFormatEmail(Email))
          {
            SendConfirm(); 
          }
          else
          {
            ShowDialogMessage('Correo con formato incorrecto.');
          }
        } 
        else{
          ShowDialogMessage('Las contraseñas no coinciden.')
        }
      }
      else{
        ShowDialogMessage('Debes llenar los campos correctamente.')
      }
    }

    function SendConfirm(){
      Swal.fire({
        title: 'Guardar?',
        text: "¿Esta seguro que desea Guardar?",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, Guardar!'
      }).then((result) => {
        if (result.isConfirmed) {
          PostSaveUserNew();
        }
      })
  }

    const ValidatePassword = () =>{
      var valid = true;
      if (Password == RepeatPassword) {
        valid = true;
      }
      else{
        valid = false;
      }
      return valid;
    }

    function validateFormatEmail (email) {
      let RegEx = /[^@]+@[^@]+\.[a-zA-Z]{2,}$/;
      if(email.match(RegEx)){
          return true;
      }
      else{
        return false;
      }
    }

    const ValidateCampos = () =>{
      var valid = true;
      if (UserName === "") {
        valid = false;
      }
      if (Password === "") {
        valid = false;
      }
      if (RepeatPassword === "") {
        valid = false;
      }
      if (Name === "") {
        valid = false;
      }
      if (LastName === "") {
        valid = false;
      }
      if (Email === "") {
        valid = false;
      }
      if (UserType === 0) {
        valid = false;
      }
      if (Document === "") {
        valid = false;
      }
      else if (!valid){
        valid = false;
      }
      return valid;
    }

    function AlertLoad(){
      Swal.fire(
        'Hecho',
        'Se ha Guardado Satisfactoriamente.',
        'success',
      ).then((result) => {
        setGoMessageFinal(true);
      })
    }


    const PostSaveUserNew = () =>{        
      const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
      let POST_SAVE_USER = process.env.REACT_APP_API_POST_INSERTUSER;
      let URL = URL_BASE+POST_SAVE_USER;        
      const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
      
      var obj = {
        UserLogin: UserName,
        Contraseña: Password,
        Nombres: Name,
        Apellidos: LastName,
        Email: Email,
        TipoUsuario: UserType,
        Documento : Document,
        RepetirContraseña : RepeatPassword,
      }
      setLoading(true);
      try{
        fetch(URL,{
          method:'post',
          mode: 'cors',
          headers: {
              'Accept':'application/json',
              'Authorization': 'Bearer ' + tokenLocalStorage,
              'Content-type': 'application/json'
          },
          body: JSON.stringify(obj)
          }).then(function(res){
              return res.json();
          }).then(function(response){
              setLoading(false);

              if(response.Code === 0){                 
                  if(response.Data){ 
                    if (response.Data.Errors) {
                      ShowDialogMessage(response.Data.Errors)
                    }                          
                    else{
                      AlertLoad();
                    }
                  }else{
                      ShowDialogMessage(response.Message)
                  }

              }else if (response.Code === 1){
                      ShowDialogMessage(response.Message);

              }else if (response.Code === 2){
                  var message = "";
                  if(response.Data.Errors.length > 0){
                      response.Data.Errors.forEach(element => {
                          message = `${element.FieldName}. ${element.Message}. `
                      });
                      ShowDialogMessage(message);                        
                  }
              }

          }).catch((error) =>{
              console.log(error);
          })
      }catch(e){
          console.log(e);
      }
    }

    const handleChangeUserType = (event) =>{
      setUserType(event.target.value);
    }

    const handleFilterDocument = (event) => {
      setDocument(event.target.value);
    }

    const handleFilterName = (e) =>{
      setName(e.target.value.toString());
    }

    const handleFilterLastName = (e) =>{
      setLastName(e.target.value.toString());
    }

    const handleFilterEmail = (e) =>{
      setEmail(e.target.value.toString());
    }

    const handleFilterUserName = (e) =>{
      setUserName(e.target.value.toString());
    }

    const handleFilterPassword = (e) =>{
      setPassword(e.target.value.toString());
    }

    const handleFilterRepeatPassword = (e) =>{
      setRepeatPassword(e.target.value.toString());
    }

    if(newRegister){
        //return <NuevoPolitica  id={1}/>
    }

    if(EditRegister){
      //return <EditUser id={IdEdit}/>  /*id={1}*/
    }

    if(GoMessageFinal){
      return <AdminUsuario/>
    }
    

    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
  }

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Nuevo Usuario</h1>
              </Grid>
            </Grid> 
            <div style={{paddingBottom:20}}>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Login</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterUserName}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Nombres</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterName}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Apellidos</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterLastName}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Correo</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterEmail}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Contraseña</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" type="password" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterPassword}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Repetir Contraseña</a>
                </Grid>
                <Grid item xs={3}>
                    <TextField id="outlined-margin-dense" type="password" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterRepeatPassword}/>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Tipo Usuario</a>
                </Grid>
                <Grid item xs={3}>
                  <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="TypeUserId" id="TypeUserId" className="form-control" onChange={handleChangeUserType}>
                      <option value="0">- Seleccione Tipo Usuario -</option>
                      <option value="AD">Administrador</option>
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
              <Grid container>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                  <a style={{fontSize:18}}>Documento</a>
                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterDocument}/>
                </Grid>
              </Grid>
            </div>
            <div>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={handleClickSweet}>
                  Guardar
                </Button>
              </Grid>
              <Grid item xs={2} style={{paddingBottom:10, textAlign:'left'}}>
                <Button variant="contained" className={classes.btncancelar} onClick={handleClick}>
                  Cancelar
                </Button>
              </Grid>
            </Grid>
            <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
            <ProgressIntoDialog open={loading} />
            </div>
        </div>
    )
}