import React from 'react';
import { Grid } from '@material-ui/core';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import BorderColorSharpIcon from '@material-ui/icons/BorderColorSharp';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined';
import Swal from 'sweetalert2';
import TablePagination from '@material-ui/core/TablePagination';
import FormControl from '@material-ui/core/FormControl';
import "./Book.css";
import NewBook from './NewBook';
import EditBook from './EditBook';
import UnauthorizedToken from '../dialogs/UnauthorizedToken';

const StyledTableCell = withStyles((theme) => ({
    head: {
      backgroundColor: '#ffff',
      color: "black",
      borderBottom: '3px solid #dee2e6',
      borderRight: '2px solid #dee2e6',
      fontWeight: 600,
    },
    body: {
      fontSize: 14,
    },
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
    root: {
      '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover,
      },
    },
  }))(TableRow);
  
  function createData(name, calories, fat, carbs) {
    return { name, calories, fat, carbs };
  }
  
  const rows = [
    createData('1', 'Edwin', '561615616', 'Activo'),
  ];
  
  const useStyles = makeStyles({
    table: {
      minWidth: 500,
    },
  });

export default function Book (props){
    const classes = useStyles();    
    const [param, setParam] = React.useState(4);
    const [newRegister, setNewRegister] = React.useState(false);
    const [EditRegister, setEditRegister] = React.useState(false);
    const [ListUser, setListUser] = React.useState([]);
    const [IdEdit, setIdEdit] = React.useState('');
    const [NameCall, setNameCall] = React.useState('');
    const [UserCodeDelete, setUserCodeDelete] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [StatusDelete, setStatusDelete] = React.useState(false);
    const [messageError,setMessageError] = React.useState('');
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(5);
    const [Name, setName] = React.useState('-');
    const [UserType, setUserType] = React.useState(0);
    const [ListCourses, setListCourses] = React.useState([]);
    const [ListGrades, setListGrades] = React.useState([]);
    const [ListBooks, setListBooks] = React.useState([]);
    const [CourseId, setCourseId] = React.useState(0);
    const [GradeId, setGradeId] = React.useState(0);
    const [Title, setTitle] = React.useState('-');
    const [CodeLibroDelete, setCodeLibroDelete] = React.useState('');
    const [ValidToken, setValidToken] = React.useState(false);

    React.useEffect(()=>{
      GetListCourse();
      GetListGrade();
      GetListBook();
    },[])

    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };

    function handleClick() {
      setNewRegister(true);
    }

    const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
    }

    function handleClickSweet() {
      GetListBook();
    }

    const handleClickEdit = (e) =>{
      setIdEdit(e.currentTarget.id);
      setStatusDelete(false);
      setEditRegister(true);
    }

    const handleClickDelete = (e) =>{      
      setCodeLibroDelete(e.currentTarget.id);
      setStatusDelete(true);
    }
    if(StatusDelete){
      DeleteConfirm();
    }

    function DeleteConfirm(){
      if(CodeLibroDelete.length > 0){
        Swal.fire({
          //title: 'Eliminar?',
          title: 'Eliminar',
          text: "¿Esta seguro que desea Eliminar?",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonColor: '#3085d6',
          cancelButtonColor: '#d33',
          confirmButtonText: 'Aceptar',
          cancelButtonText: 'Cancelar'
        }).then((result) => {
          if (result.isConfirmed) {
            PostDeleteBook();
            setStatusDelete(false);
            Swal.fire(
              'Eliminado',
              'Se Eliminó Correctamente.', 
              'success'
            )
          }
          else{
            setStatusDelete(false);
          }
        })
       }
    }

    const handleFilterTitle = (e) =>{
      if(e.target.value.toString() == "")
      {
        setTitle('-');
      }
      else{
        setTitle(e.target.value.toString());
      }
    }
    const handleChangeUserType = (event) =>{
      setUserType(event.target.value);
    }

    const handleChangeCourse = (event) => {
      setCourseId(event.target.value);
    }

    const handleChangeGrade = (event) => {
      setGradeId(event.target.value);
    }

    if(newRegister){
        return <NewBook/>
    }

    if(EditRegister){
      return <EditBook id={IdEdit}/>
    }
    

    const showErrorMessage = (msg) => {
      /*if (showing){
          return;
      }

      setShow(true);
      setShowing(true);
      setMessageError(msg);
  
      setTimeout(() => {
          setShow(false);
          setShowing(false);
          setMessageError('');            
      }, 4000);*/
  }

  const PostDeleteBook = () =>{   
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let POST_DELETEBOOK = process.env.REACT_APP_API_POST_DELETEBOOK;
    let URL = URL_BASE+POST_DELETEBOOK;     
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    var obj = {
      Code: CodeLibroDelete
    }
    try{
      fetch(URL,{
        method:'post',
        mode: 'cors',
        headers: {
            'Accept':'application/json',
            'Authorization': 'Bearer ' + tokenLocalStorage,
            'Content-type': 'application/json'
        },
        body: JSON.stringify(obj)
        }).then(function(res){
          if(res.status === 401){
            return 401;  
          }
            return res.json();
        }).then(function(response){
            if(response.Code === 0){                    
              GetListBook();                     
              setStatusDelete(false);            

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    ShowDialogMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListCourse = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_COURSE = process.env.REACT_APP_API_GET_COURSES;
    let URL = URL_BASE+GET_COURSE;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){
          if(res.status === 401){
            return 401;  
          }
            return res.json();
        }).then(function(response){

            if(response.Code === 0){
                setListCourses(response.Data)

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListGrade = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_GRADE = process.env.REACT_APP_API_GET_GRADE;
    let URL = URL_BASE+GET_GRADE;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){
          if(res.status === 401){
            return 401;  
          }
            return res.json();
        }).then(function(response){

            if(response.Code === 0){
                setListGrades(response.Data)

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }

  const GetListBook = () =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    let GET_BOOKS = process.env.REACT_APP_API_GET_BOOKS;
    let URL = URL_BASE+GET_BOOKS+ CourseId + '/' + GradeId + '/' + Title;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN)
    try{
        fetch(URL,{
            method:'get',
            mode: 'cors',
            headers: {
                'Accept':'application/json',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                'Content-type': 'application/json'
            }                
        }).then(function(res){
          if(res.status === 401){
            return 401;  
          }
            return res.json();
        }).then(function(response){

            if(response.Code === 0){
              setListBooks(response.Data)

            }else if (response.Code === 1){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element}.`
                    });
                    showErrorMessage(message);                        
                }

            }else if (response.Code === 2){
                var message = "";
                if(response.Data.Errors.length > 0){
                    response.Data.Errors.forEach(element => {
                        message = `${element.FieldName}. ${element.Message}. `
                    });
                    showErrorMessage(message);                        
                }
            }
            else if(response === 401){
              setValidToken(true);
            }

        }).catch((error) =>{
            console.log(error);
        })
    }catch(e){
        console.log(e);
    }
  }



  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (       
        <div style={{marginLeft:50, marginRight:30, backgroundColor:'#fff'}}>
            <Grid container style={{marginTop:20}}>
              <Grid item xs={12} style={{borderTop:3, borderTopColor:'#919295', borderTopStyle:'solid', 
                borderBottomWidth:2, borderBottomColor:'#919295', borderBottomStyle:'solid' }}>
                  <h1 style={{margin:5, fontSize:'18pt'}}>Administración de Libros</h1>
              </Grid>
            </Grid> 
            <div>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Curso</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="CursoId" id="CursoId" className="form-control" value={CourseId} onChange={handleChangeCourse}>
                      <option value="0">- Seleccione Curso -</option>
                        {
                        ListCourses.map((element,key) =>
                        <option value={element.IdCursoLibro}>{element.NombreCurso}</option>
                        )}   
                      </select>
                    </FormControl>
                </Grid>
                <Grid item xs={2} style={{paddingTop:15}} >
                  <a style={{fontSize:18}}>Grado</a>
                </Grid>
                <Grid item xs={3}>
                    <FormControl variant="outlined" style={{width:'100%', paddingTop:5}} className={classes.formControl} >
                      <select name="GradoId" id="GradoId" className="form-control" value={GradeId} onChange={handleChangeGrade}>
                      <option value="0">- Seleccione Grado -</option>
                        {
                          ListGrades.map((element,key) =>
                          <option value={element.IdGradoInstruccion}>{element.NombreGrado}</option>
                        )} 
                      </select>
                    </FormControl>
                </Grid>
              </Grid>
            </div>
            <div style={{paddingBottom:20}}>
              <Grid container style={{marginTop:30}}>
                <Grid item xs={1} >
                </Grid>
                <Grid item xs={2} style={{paddingTop:15, textAlign:'left'}} >
                    <a style={{fontSize:18}}>Título</a>
                </Grid>
                <Grid item xs={3}>
                  <TextField id="outlined-margin-dense" className={classes.formControl} margin="dense" label="" variant="outlined" style={{width:'100%'}} onChange={handleFilterTitle}/>
                </Grid>
              </Grid>
            </div>
            <div>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left',paddingRight:5}}>
                <Button variant="contained" className="btn-consultar" onClick={handleClickSweet}>
                  Consultar
                </Button>
              </Grid>
              <Grid item xs={1} style={{paddingBottom:10, textAlign:'left'}}>
                <Button variant="contained" className="btn-nuevo" onClick={handleClick}>
                  Nuevo
                </Button>
              </Grid>
            </Grid>
            <Grid container >
              <Grid item xs={1} >
              </Grid>
            <Grid item xs={10} >
              <TableContainer component={Paper}></TableContainer>
              <Table className={classes.table, 'table-borde'} aria-label="customized table">
              <TableHead>
              <TableRow style={{border:'2px solid #dee2e6'}}>
                  <StyledTableCell>Acciones</StyledTableCell>
                  <StyledTableCell align="left">Título</StyledTableCell>
                  <StyledTableCell align="left">Edición</StyledTableCell>
                  <StyledTableCell align="left">Grado</StyledTableCell>
                  <StyledTableCell align="left">Curso</StyledTableCell>
                  <StyledTableCell align="left">FechaPublicación</StyledTableCell>
              </TableRow>
              </TableHead>
              <TableBody>
                {
                  ListBooks.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((element,key) =>
                  <StyledTableRow>
                    <StyledTableCell align="left" className="table-border-right">
                        <Grid item>
                          <Button id={element.CodeLibro} onClick={(e) => handleClickEdit(e)} style={{minWidth:40}}/*onClick={handleClickEdit}*/>
                            <BorderColorSharpIcon/>
                          </Button>
                          <Button id={element.CodeLibro} onClick={(e) => handleClickDelete(e)} style={{minWidth:40}}>
                          <DeleteOutlinedIcon />
                          </Button>
                        </Grid>
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">
                      {element.Titulo}
                    </StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.Edicion}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreGrado}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.NombreCurso}</StyledTableCell>
                    <StyledTableCell align="left" className="table-border-right">{element.CFechaPublicacion}</StyledTableCell>
                  </StyledTableRow>
                )}
                {emptyRows > 0 && (
                <TableRow style={{ height: 1 * emptyRows }}>
                  <TableCell colSpan={6} style={{display:'none'}}/>
                </TableRow>
                )}
              </TableBody>              
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 15]}
                component="div"
                count={ListBooks.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onChangePage={handleChangePage}
                onChangeRowsPerPage={handleChangeRowsPerPage}
              />
            </Grid>
            <Grid item xs={1} >
            </Grid>
            </Grid>
            <UnauthorizedToken open={ValidToken} />
            </div>
        </div>
    )
}