import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import ExitToAppOutlinedIcon from '@material-ui/icons/ExitToAppOutlined';
import EditIcon from '@material-ui/icons/Edit';

const useStyles_fab = makeStyles((theme) => ({
    align: {
        textAlign: "center"
    },
    root: {
        '& > *': {
        margin: theme.spacing(1),
        },
    },
    extendedIcon: {
        marginRight: theme.spacing(1),
    },
}));

const ButtonLogin = (props) => {
    const classes_fab = useStyles_fab();
    return (
        <div className={classes_fab.root, classes_fab.align}>
            <Fab 
                variant="contained"
                color="primary" 
                onClick={props.handleClick}
                style={{width:200, backgroundColor:'#0062cc'}}
            >   
                {props.nameAction} 
            </Fab>
        </div>
    )
}

export default ButtonLogin