import React from 'react';
import clsx from 'clsx';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import CssBaseline from '@material-ui/core/CssBaseline';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import NotificationsIcon from '@material-ui/icons/Notifications';
import PersonIcon from '@material-ui/icons/Person';
import PeopleIcon from '@material-ui/icons/People';
import HoraryIcon from '@material-ui/icons/DateRange';
import UnarchiveIcon from '@material-ui/icons/Unarchive';
import MenuBookIcon from '@material-ui/icons/MenuBook';
//import './Drawer.css'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import MenuItem from '@material-ui/core/MenuItem';
import Menu from '@material-ui/core/Menu';
import Progress from '../progress/Progress';
import Main from './Main';
import { Redirect } from 'react-router';
import ChangePassword from '../dialogs/ChangePassword';
import TreeMenu from '../tree/TreeMenu';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
	},
	appBar: {
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
	},
	appBarShift: {
		width: `calc(100% - ${drawerWidth}px)`,
		marginLeft: drawerWidth,
		transition: theme.transitions.create(['margin', 'width'], {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		backgroun: '#343a40 !important',
	},
	menuButton: {
		marginRight: theme.spacing(2),
	},
	hide: {
		display: 'none',
	},
	drawer: {
		width: drawerWidth,
		flexShrink: 0,
	},
	drawerPaper: {
		width: drawerWidth,
		background: '#343a40 !important',
	},
	drawerHeader: {
		display: 'flex',
		alignItems: 'center',
		padding: theme.spacing(0, 1),
		// necessary for content to be below app bar
		...theme.mixins.toolbar,
		justifyContent: 'flex-end',
	},
	content: {
		flexGrow: 1,
		padding: theme.spacing(3),
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.sharp,
			duration: theme.transitions.duration.leavingScreen,
		}),
		marginLeft: -drawerWidth,
	},
	contentShift: {
		transition: theme.transitions.create('margin', {
			easing: theme.transitions.easing.easeOut,
			duration: theme.transitions.duration.enteringScreen,
		}),
		marginLeft: 0,
	},
}));

export default function DrawerCustom() {
	const classes = useStyles();
	const theme = useTheme();
	const [open, setOpen] = React.useState(true);
	const [param, setParam] = React.useState(0);
	const [anchorEl, setAnchorEl] = React.useState(null);
	const [showListBooks, setShowListBooks] = React.useState(false);
	const [openChangePassword, setOpenChangePassword] = React.useState(false);
	const [name, setName] = React.useState('');
	//----loader----
	const [loading, setLoading] = React.useState(false);
	//---message error-----
	const [show, setShow] = React.useState(false);
	const [messageError, setMessageError] = React.useState(false);
	const [logout, setLogout] = React.useState(false);
	const [unauthorized, setUnauthorized] = React.useState(false);
	const [showing, setShowing] = React.useState(false);
	//---------
	const showErrorMessage = (msg) => {
		if (showing) {
			return;
		}

		setShow(true);
		setShowing(true);
		setMessageError(msg);

		setTimeout(() => {
			setShow(false);
			setShowing(false);
			setMessageError('');
		}, 4000);
	};

	const handleClose = () => {
		setShow(false);
	};

	const setNameToolbar = (view) => {
		if (view === 1) setName('Gestión de Libros');
		else if (view === 2) setName('Mantenimiento de Usuarios');
		else if (view === 3) setName('Consulta de Alumnos');
		else if (view === 4) setName('Consulta de Docentes');
		else if (view === 5) setName('Mantenimiento de Colegios');
		else if (view === 6) setName('Consulta de Activación');
		else if (view === 7) setName('Mantenimiento de Promotor');
    	else if (view === 99) setName('Dashboard');
		else if (view === 8) setName('Mantenimiento de Colección');
	};

	const ShowDialogMessage = (message) => {
		setShow(true);
		setMessageError(message);
	};
	//----- popup validate nro book ------
	const [showValidate, setShowValidate] = React.useState(false);

	const handleCloseValidate = () => {
		setShowValidate(false);
		setShowActivate(true);
	};
	//----- popup activate code book------
	const [showActivate, setShowActivate] = React.useState(false);
	const [showValidateCode, setShowValidateCode] = React.useState(false);

	const handleCloseActivate = () => {
		setShowActivate(false);
		setShowListBooks(true);
	};
	//------- popup validate code book -----------------------------
	const handleCloseValidateCode = () => {
		setShowValidateCode(false);
	};
	const handleCloseAndOpenActive = () => {
		setShowValidateCode(false);
		setShowActivate(true);
	};
	//------------popup welcome message ------------------------
	const [showWelcomeMessage, setShowWelcomeMessage] = React.useState(true);

	const handleCloseWelcomeMessage = () => {
		setShowWelcomeMessage(false);
		setShowValidate(true);
	};
	//------------show list books---------------------------------------
	const handleDrawerOpen = () => {
		setOpen(true);
	};
	const handleDrawerClose = () => {
		setOpen(false);
	};
	const setOptionView = (view) => {
		console.log('parametro vista');
		console.log(view);
		setParam(view);
		setNameToolbar(view);
	};
	const handleCloseMenu = () => {
		setAnchorEl(null);
	};
	const handleMenu = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleCloseChangePassword = () => {
		setOpenChangePassword(false);
	};
	const handleChangePassword = () => {
		setOpenChangePassword(true);
	};
	const handleCloseSession = () => {
		setAnchorEl(null);
		setLoading(true);
		var obj = {
			Token: localStorage.getItem(process.env.REACT_APP_KEY_TOKEN),
			RefreshToken: localStorage.getItem(
				process.env.REACT_APP_KEY_REFRESH_TOKEN
			),
		};

		const URL_BASE = process.env.REACT_APP_URL_BASE_SEGURIDAD;
		let POST_LOGOUT = process.env.REACT_APP_API_POST_LOGOUT;
		let URL = URL_BASE + POST_LOGOUT;
		const tokenLocalStorage = localStorage.getItem(
			process.env.REACT_APP_KEY_TOKEN
		);
		const Apikey = process.env.REACT_APP_API_KEY_SEGURIDAD;
		try {
			fetch(URL, {
				method: 'post',
				mode: 'cors',
				headers: {
					Accept: 'application/json',
					ApiKey: Apikey,
					'Content-type': 'application/json',
				},
				body: JSON.stringify(obj),
			})
				.then(function (res) {
					if (res.status == 401) {
						return 401;
					}
					return res.json();
				})
				.then(function (response) {
					setLoading(false);
					if (response != 401) {
						if (response) {
							localStorage.setItem(
								process.env.REACT_APP_KEY_TOKEN,
								'key-token'
							);
							localStorage.setItem(
								process.env.REACT_APP_KEY_REFRESH_TOKEN,
								'key-refresh-token'
							);

							setLogout(true);
						} else {
							showErrorMessage('ocurrió un error');
						}
					} else {
						setUnauthorized(true);
					}
				})
				.catch((error) => {
					console.log(error);
				});
		} catch (e) {
			console.log(e);
		}
	};

	if (logout) {
		return <Redirect to="/" />;
	}

	console.log(showValidate);
	const PERFILID = 1;

	return (
		<div className={classes.root}>
			<CssBaseline />
			<AppBar
				position="fixed"
				className={clsx(classes.appBar, {
					[classes.appBarShift]: open,
				})}
			>
				<Toolbar>
					<IconButton
						color="inherit"
						aria-label="open drawer"
						onClick={handleDrawerOpen}
						edge="start"
						className={clsx(classes.menuButton, open && classes.hide)}
					>
						<MenuIcon style={{ color: 'black' }} />
					</IconButton>
					{theme.direction === 'ltr' ? (
						<Typography
							variant="h6"
							noWrap
							style={{
								width: '90%',
								textAlign: 'left',
								color: 'rgba(0,0,0,.5)',
								fontWeight: 'bolder',
							}}
						>
							{name}
						</Typography>
					) : (
						''
					)}

					{true && (
						<div style={{ width: '100%', textAlign: 'right' }}>
							<IconButton
								aria-label="account of current user"
								aria-controls="menu-appbar"
								aria-haspopup="true"
								onClick={handleMenu}
								color="inherit"
								style={{ marginLeft: 5 }}
							>
								<AccountCircleIcon style={{ color: 'black' }} />
							</IconButton>
							<Menu
								id="menu-appbar"
								anchorEl={anchorEl}
								anchorOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								keepMounted
								transformOrigin={{
									vertical: 'top',
									horizontal: 'right',
								}}
								open={Boolean(anchorEl)}
								onClose={handleCloseMenu}
							>
								<MenuItem className="fontMenuItem" onClick={handleCloseSession}>
									Cerrar Sesión
								</MenuItem>
								<MenuItem
									className="fontMenuItem"
									onClick={handleChangePassword}
								>
									Cambiar Contraseña
								</MenuItem>
							</Menu>
						</div>
					)}
				</Toolbar>
				<ChangePassword
					open={openChangePassword}
					handleClose={handleCloseChangePassword}
				/>
			</AppBar>

			<Drawer
				className={classes.drawer}
				variant="persistent"
				anchor="left"
				open={open}
				classes={{
					paper: classes.drawerPaper,
				}}
			>
				<div
					className={classes.drawerHeader}
					style={{ backgroundColor: '#343a40' }}
				>
					<h3 style={{ color: '#fff' }}>BELLO HORIZONTE</h3>
					<IconButton onClick={handleDrawerClose}>
						{theme.direction === 'ltr' ? (
							<ChevronLeftIcon style={{ color: '#fff' }} />
						) : (
							<ChevronRightIcon style={{ color: 'black' }} />
						)}
					</IconButton>
				</div>
				<Divider />
				<List style={{ border: '0px solid blue' }}>
					<ListItemText
						primary={'OPCIONES DEL MENU'}
						style={{ color: '#fff' }}
					/>
					<div style={{paddingLeft:20,paddingRight:20}}>
						<TreeMenu
						selectedBookManagement={() => setOptionView(1)}
						selectedSchoolMaintenance={() => setOptionView(5)}
						selectedUsersMaintenance={() => setOptionView(2)}
						selectedStudentConsult={() => setOptionView(3)}
						selectedTeacherConsult={() => setOptionView(4)}
						selectedActivateConsult={() => setOptionView(6)}
						selectedPromoterMaintenance={() => setOptionView(7)}
						selectedReportDashboard={() => setOptionView(99)}
						selectedCollectionMaintenance={() => setOptionView(8)}
						/>
						
					</div>
				</List>
				<Divider />
			</Drawer>
			<main
				className={clsx(classes.content, {
					[classes.contentShift]: open,
				})}
			>
				<div className={classes.drawerHeader} />
				{<Main param={param} />}
			</main>
			<Progress open={loading} />
		</div>
	);
}
