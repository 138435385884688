import React from 'react';
import { withStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import { Grid } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import ShowDialog from '../dialogs/ShowDialog';
import ProgressIntoDialog from '../progress/Progress';
import Swal from 'sweetalert2';
import YearDatePicker from '../date/YearDatePicker.js';
import {MainContext} from '../../context/MainAppProvider';
import FormControl from '@material-ui/core/FormControl';

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[900],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const useStyles = makeStyles({
  table: {
    minWidth: 500,
  },
  btncancelar: {
    color: '#444 !important',
    backgroundColor: '#f8f9fa !important',
    border: '1px solid #ddd',
    boxShadow: 'none',
    textTransform: 'none !important',
    width: '30%',
    textAlign: 'left',
    marginLeft: 20,
  },
  btnenviar: {
    color: '#ffff !important',
    background: 'rgb(0, 98, 204) !important',
    border: '1px solid #ddd',
    boxShadow: 'none',
    textTransform: 'none !important',
    width: '30%',
    textAlign: 'left',
    marginLeft: 20,
  },
});


export default function ActivationsCode(props) {
  const [open, setOpen] = React.useState(false);
  const classes = useStyles();   
  const [show, setShow] = React.useState(false);
  const [NombreColegio, setNombreColegio] = React.useState('');
  const [Direccion, setDireccion] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [messageError,setMessageError] = React.useState('');
  const [GoMessageFinal, setGoMessageFinal] = React.useState(false);
  const {getDateLocal,getYearToday,getMonthToday}  = React.useContext(MainContext);
  const [dateInit, setDateInit] = React.useState(new Date(getDateLocal()));
  const [year, setYear] = React.useState(getYearToday());
  const [FileExcel, setFile] = React.useState("");
  const [OpenLoad, setOpenLoad] = React.useState(false);
  const [ViewArchive, setViewArchive] = React.useState(false);
  const [ViewDataError, setViewDataError] = React.useState(false);
  const [ErrorAndExcel, setErrorAndExcel] = React.useState(false);

  React.useEffect(()=>{
  },[])

  const handleClose = () => {
    props.handleClose(true);
    setShow(false);
  };

  const handleEnvio = () =>{
      console.log(FileExcel);
      if(FileExcel != ''){
        UploadExcel();
      }
      else{
        ShowDialogMessage('Debes seleccionar un archivo.')
      }
  }

  const ShowDialogMessage = (message) =>{
    setShow(true);
    setMessageError(message);
  }

  function AlertLoad(){
    props.handleClose(true);
    Swal.fire(
      'Hecho',
      'Se ha Guardado Satisfactoriamente.',
      'success',
    ).then((result) => {
      setGoMessageFinal(true);
      setNombreColegio("");
      setDireccion("");
    })
  }
 
  const showErrorMessage = (msg) => {
    /*if (showing){
        return;
    }

    setShow(true);
    setShowing(true);
    setMessageError(msg);

    setTimeout(() => {
        setShow(false);
        setShowing(false);
        setMessageError('');            
    }, 4000);*/
  }

  function handleUpload(event) {
    setFile(event.target.files[0]);
    //UploadExcel(event);
  }

  const handleDateInit = (date) => {
    let month = date.month() + 1;
    let year = date.year();
    setDateInit(date);
    setYear(date.year());
  };

  function ArchiveIncorrect() {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    swalWithBootstrapButtons.fire(
        'Incorrecto',
        'Se Necesita un Formato Excel',
        'error'
    )
    handleClose();
    setViewArchive(false);
    setViewDataError(true); 
  }

  function IncorrectData(message) {
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    swalWithBootstrapButtons.fire(
        'Incorrecto',
        message,
        'error'
    )
    handleClose();
    setViewArchive(false);
    setViewDataError(true); 
  }

  function ArchiveColumnsIncorrect(message) {

    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    swalWithBootstrapButtons.fire(
        'Error',
        message,
        'error'
    )
    setErrorAndExcel(true);
    setViewArchive(false);
    setViewDataError(false);      
  }

  const showDialogError = (message) =>{        
    setViewArchive(false);
    setViewDataError(true);
  }

  const showDialogErrorExcel = () =>{
    const swalWithBootstrapButtons = Swal.mixin({
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger'
        },
        buttonsStyling: true
    })

    swalWithBootstrapButtons.fire(
        'Incorrecto',
        'Excel datos incorrectos',
        'error'
    )
    setViewArchive(false);
    setViewDataError(true);
    setErrorAndExcel(false);
  }

  const UploadExcel = () =>{

    const formDataToUpload = new FormData();
    const files = FileExcel;
    formDataToUpload.append('File', files);
    formDataToUpload.append('Periodo', year);

    console.log(formDataToUpload);

    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;
    const POST_UPLOAD_EXCEL = process.env.REACT_APP_API_POST_UPLOAD_EXCEL;
    const URL = URL_BASE+POST_UPLOAD_EXCEL;
    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    try{
        setOpenLoad(true);
        fetch(URL,{
            method:'post',
            mode: 'cors',
            headers: {
                //'Accept':'application/json',
                //'Content-Type': 'multipart/form-data',
                'Authorization': 'Bearer ' + tokenLocalStorage,
                //'Content-Type': undefined,
            },
            body: formDataToUpload

        }).then(function(res){
            if(res.status === 401){
                return 401;
            }
            return res.json();
        }).then(function(response){
            setOpenLoad(false);

            if (response.Code == 0){
                //setColumnasExcel(5);
                if(response.Data === null ){
                    ArchiveIncorrect();
                }
                else{
                    if(response.Data.Success){
                        AlertLoad();
                    }
                    else{
                        ArchiveIncorrect();
                    }
                }
            }
            else if(response.Code == 1){
                if (response.Data == null) {
                    IncorrectData(response.Message);
                }
            }
            else if (response.Code == 2){
                if(response.Data === null){
                    ArchiveIncorrect();
                }
                else{
                    //setListErrors(response.Data.Errors);
                    //setColumnasExcel(4);
                    ArchiveColumnsIncorrect(response.Message);
                }
            }
            else{
                showDialogErrorExcel();
            }           

        }).catch((error) =>{
            showDialogError(error);
        })
    }catch(e){
        showDialogError(e);
    }
  }

  return (
    <div>
      <Dialog onClose={props.handleClose} aria-labelledby="customized-dialog-title" open={props.open}>
        <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
            Nuevos Códigos de Activación
        </DialogTitle>
        <DialogContent dividers>
            <Typography gutterBottom style={{display:'none'}}>
                Cras mattis consectetur purus sit amet fermentum. Cras justo odio, dapibus ac facilisis
                in, egestas eget quam. Morbi leo risus, porta ac consectetur ac, vestibulum at eros.
            </Typography>

            <Typography gutterBottom>
                <Grid container>
                    <Grid item xs={4} style={{paddingTop:15}} >
                        <a style={{fontSize:15}}>Periodo:</a>
                    </Grid>
                    <Grid item xs={4}>
                        <YearDatePicker
                        selectedDate={dateInit} 
                        handleDateChange={handleDateInit} />
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={4} style={{paddingTop:15}} >
                        <a style={{fontSize:15}}>Códigos:</a>
                    </Grid>
                    <Grid item xs={8}>
                        <input
                            accept="text/plain, .csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                            className={classes.input}
                            id="contained-button-file"
                            type="file"
                            onChange={handleUpload}
                        />
                    </Grid>
                </Grid>
            </Typography>
        </DialogContent>
        <DialogActions>
          <Button autoFocus onClick={handleEnvio} className={classes.btnenviar} color="primary">
            Enviar
          </Button>
          <Button autoFocus onClick={handleClose} className={classes.btncancelar} color="primary">
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
      <ShowDialog open={show} message={messageError} handleClose={handleClose}/>
      <ProgressIntoDialog open={loading} />
    </div>
  );
}