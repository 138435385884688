import 'date-fns';
import React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from 'moment'
import 'moment/locale/es';


export default function MonthDatePicker(props) {

  moment.locale('es'); 

  return (
    <MuiPickersUtilsProvider locale="es" utils={MomentUtils}>
      <Grid container justify="space-around">
        <KeyboardDatePicker 
          //disableToolbar
          views={["year", "month"]}
          autoOk
          //variant="inline"
          //format={(date) => moment(date).format('DD-MM-YYYY')}
          //format="dddd, DD [de] MMMM [del] YYYY"
          format="MMMM YYYY"
          margin="normal"
          //id="date-picker-inline"
          //label="Date picker inline"
          //disableFuture={true}
          value={props.selectedDate}
          style={{width:'100%',margin:0}}
          onChange={props.handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',
          }}
          InputProps={{ readOnly: true }}
        />
      </Grid>
    </MuiPickersUtilsProvider>
  );
}
