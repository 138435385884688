import React from 'react'
import {
  CWidgetDropdown,
  CRow,
  CCol,
  CDropdown,
  CDropdownMenu,
  CDropdownItem,
  CDropdownToggle
} from '@coreui/react'
//import CIcon from '@coreui/icons-react'
import ChartLineSimple from '../charts/ChartLineSimple'
import Progress from '../progress/Progress';
import AlertDialog from '../dialogs/ShowDialog';
import UnauthorizedDialog from '../dialogs/UnauthorizedToken';
import ConstantAction from '../../config/ConstantAction';

const WidgetsDropdown = () => {

  const [student, setStudent] = React.useState(0);
  const [studentActived, setStudentActived] = React.useState(0);
  const [teacher, setTeacher] = React.useState(0);
  const [teacherActived, setTeacherActived] = React.useState(0);
  const [schoolRegister, setSchoolRegister] = React.useState(0);
  //----loader----
  const [loading, setLoading] = React.useState(false);
  //---message error-----
  const [show, setShow] = React.useState(false);
  const [messageError, setMessageError] = React.useState(false);
  //------------------------flag vencimiento de token------------------------------
  const [showUnauthorized, setShowUnauthorized] = React.useState(false);
  //------------
  const handleClose = () => {
      setShow(false)       
  };
  const ShowDialogMessage = (message) =>{
      setShow(true);
      setMessageError(message);
  }
  //----------- impl method async ------------------------------------
  const asyncCallMethod = (typeCall,obj,methodAction,typeAction) =>{
    const URL_BASE = process.env.REACT_APP_URL_BASE_ADMIN;

    const tokenLocalStorage = localStorage.getItem(process.env.REACT_APP_KEY_TOKEN);
    const paramsHeadersPost = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      },
      body: JSON.stringify(obj)
    }
    const paramsHeadersGet = {
      method:typeCall,
      mode: 'cors',
      headers: {
          'Accept':'application/json',
          'Authorization': 'Bearer ' + tokenLocalStorage,
          'Content-type': 'application/json'
      }
    }
    const header = obj !== null ? paramsHeadersPost : paramsHeadersGet ;
    const URL = URL_BASE+methodAction;    
    setLoading(true);
    try{
        fetch(URL,header).then(function(res){
            if(res.status === 401){
                return 401;
              }
            return res.json();

        }).then(function(response){    
            setLoading(false);
            if(response === 401){
                setShowUnauthorized(true);

            }else if(response.Code === 0){
                SetConfigResponse(response,typeAction);                    

            }else if (response.Code === 1){
                errorCode1(response);
        
            }else if (response.Code === 2){
                errorCode2(response);        
            }
        }).catch((error) =>{
            setLoading(false);
            console.log(error);
        })
    }catch(e){
        setLoading(false);
        console.log(e);
    }                 
  }
  const SetConfigResponse = (response,typeAction)=>{
      if(typeAction === ConstantAction.ACTION_GET_REPORT_USER){
          ResponseGetReportUser(response);
      }
  }
  const errorCode1 = (response) =>{
      var message = "";
      if(response.Data.StatusCode === 500){
          ShowDialogMessage(response.Data.Message);
      }
      else if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element}.`
          });
          ShowDialogMessage(message);                        
      }
  }
  const errorCode2 = (response) =>{
      var message = "";
      if(response.Data.Errors.length > 0){
          response.Data.Errors.forEach(element => {
              //console.log(element);
              message = `${element.FieldName}. ${element.Message}. `
          });
          ShowDialogMessage(message);                        
      }
  }
  //--------------init--------------------------------
  React.useEffect(() => {
    GetReportUser();
  },[]);

  const GetReportUser = () =>{
    const methodAction = process.env.REACT_APP_GET_REPORT_USER;
    asyncCallMethod("get",null,methodAction,ConstantAction.ACTION_GET_REPORT_USER);
  };
  const ResponseGetReportUser = (response) =>{
    //console.log(response);
    setStudent(response.Data.Alumnos);
    setStudentActived(response.Data.CodigosAlumnosActivados);
    setTeacher(response.Data.Docentes);
    setTeacherActived(response.Data.CodigosDocentesActivados);
    setSchoolRegister(response.Data.CantidadColegiosRegistrados);
  }

  // render
  return (
    <CRow>
      <CCol xs="12" lg="1"/>

      <CCol xs="6" lg="2">
        <CWidgetDropdown
          color="gradient-primary"
          header={`${student}`}
          text="Alumnos"
          className="centerText"
        >
        </CWidgetDropdown>
      </CCol>

      <CCol xs="6" lg="2">
        <CWidgetDropdown
          color="gradient-info"
          header={`${studentActived}`}
          text="Cod. Activ. Alumnos"
          className="centerText"
        >
        </CWidgetDropdown>
      </CCol>

      <CCol xs="6" lg="2">
        <CWidgetDropdown
          color="gradient-warning"
          header={`${teacher}`}
          text="Docentes"
          className="centerText"
        >
        </CWidgetDropdown>
      </CCol>

      <CCol xs="6" lg="2">
        <CWidgetDropdown
          color="gradient-danger"
          header={`${teacherActived}`}
          text="Cod. Activ. Docentes"
          className="centerText"
        >
        </CWidgetDropdown>
      </CCol>
    
      <CCol xs="6" lg="2">
        <CWidgetDropdown
          color="gradient-info"
          header={`${schoolRegister}`}
          text="Colegios registrados"
          className="centerText"
          footerSlot={
            <ChartLineSimple
              pointed
              className="mt-3 mx-3 hideDiv"
              style={{height: '70px'}}
              dataPoints={[1, 18, 9, 17, 34, 22, 11]}
              pointHoverBackgroundColor="info"
              options={{ elements: { line: { tension: 0.00001 }}}}
              label="Members"
              labels="months"
            />
          }
        >
        </CWidgetDropdown>
      </CCol>
    
      <CCol xs="12" lg="1"/>
      <AlertDialog open={show} message={messageError} handleClose={handleClose}/>
      <Progress open={loading} />
      <UnauthorizedDialog open={showUnauthorized} />
    </CRow>
  )
}

export default WidgetsDropdown
