import React from 'react';
import Welcome from './Welcome';
import Book from '../book/Book';
import School from '../school/School';
import AdminUsuario from '../users/AdminUsuario';
import Students from '../students/Students';
import Teachers from '../teachers/Teachers';
import Activate from '../activate/Activate';
import Promoter from '../promoter/promoter';
import Conexion from '../conexion/conexion';
import Dashboard from '../dashboard/Dashboard';

const OptionView = (props) => {

	//console.log('props.param');
	//console.log(props.param);
	/*
	const PERFILID = 1;

	if (PERFILID === '1') {
		if (props.param === 1) {
			return <Book />;
		}

		if (props.param === 2) {
			return <AdminUsuario />;
		}

		if (props.param === 5) {
			return <School />;
		}

		if (props.param === 3) {
			return <Welcome />;
		}
		return <Welcome />;
	} else {
		*/
		if (props.param === 1) {
			return <Book />;
		}
		if (props.param === 2) {
			return <AdminUsuario />;
		}
		if (props.param === 3) {
			return <Students />;
		}
		if (props.param === 4) {
			return <Teachers />;
		}
		if (props.param === 5) {
			return <School />;
		}
		if (props.param === 6) {
			return <Activate />;
		}
		if (props.param === 7) {
			return <Promoter />;
		}
		if (props.param === 8) {
			return <Conexion />;
		}
        if (props.param === 99) {
			return <Dashboard />;
		}
		return <Welcome />;
	/*}*/
};

export default OptionView;
