import React, {Component} from 'react';
import { withStyles, makeStyles } from "@material-ui/core/styles";
import TextField from '@material-ui/core/TextField';
import Cookies from 'js-cookie';
import { Redirect } from 'react-router';
import Grid from '@material-ui/core/Grid';
import EyeIcon from '@material-ui/icons/Visibility';
import EyeSlashIcon from '@material-ui/icons/VisibilityOff';
import './Login.css';
import ButtonLogin from '../buttons/ButtonLogin';
import Button from '@material-ui/core/Button';
import Progress from '../progress/Progress';
import ShowDialog from '../dialogs/ShowDialog';
import RecoverPassword from "../dialogs/RecoverPassword";

const TextFieldLogin = withStyles({
    root: {
        '& label': {
          color: 'black',
        },
        '& label.Mui-focused': {
          color: 'black',
        },
        '& .MuiInput-underline': {
          borderBottomColor: 'silver',
        },
        '& .MuiInput-underline:before': {
          borderBottomColor: '#009FD9',
        },
        '& .MuiInput-underline:after': {
          borderBottomColor: '#009FD9',
        },
        '& .MuiOutlinedInput-root': {
          '& fieldset': {
            borderColor: 'red',
          },
          '&:hover fieldset': {
            borderColor: 'yellow',
          },
          '&.Mui-focused fieldset': {
            borderColor: '#009FD9',
          }
        },
      },
  })(TextField);

export default class Login extends Component{   

    constructor (props){
        super(props);
        this.state = {
            userName: '',
            password: '',
            //
            show: false,
            showing: false,
            messageError:'',
            //
            loading: false,
            isLoggedIn: false,
            //
            setViewRegister: false,
            setViewRecover: false,
            init: false,
            isChecked: false,
            open:false,
            openPassword:false,
            //para valiar campos nulos
            userEmpty: false,
            passEmpty: false,
            errorEmpty: 'error-empty',
            isRevealPassword: false,
            captcha:false
        };

        this.postData = this.postData.bind(this);
        this.parseJwt = this.parseJwt.bind(this);
    }

    handleClose = () => {
        this.setState({ 
            show: false
        });        
    };

    handleClosePassword = () => {
        this.setState({ 
            openPassword: false
        });        
    };

    ShowDialogMessage = (message) =>{
        this.setState({
            show: true,
            messageError: message
        })
    }
    togglePassword = () =>{
        var val = !this.state.isRevealPassword
        this.setState({
            isRevealPassword: val
        })
    }
    
    componentDidMount () {
        let cookiesToken = Cookies.get(process.env.REACT_APP_KEY_TOKEN);
    }

    handleClickLoading = () => {
        this.setState({
            open: !this.state.open
        })
    };  

    parseJwt (token) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace('-', '+').replace('_', '/');
        return JSON.parse(window.atob(base64));
    };

    async postData (obj)  {
        let me = this;
        const URL_BASE = process.env.REACT_APP_URL_BASE_SEGURIDAD;
        console.log('url---------------');
        console.log(URL_BASE);
        let LOGIN = process.env.REACT_APP_API_POST_USER_LOGIN;
        let URL = URL_BASE+LOGIN;
        const Apikey = process.env.REACT_APP_API_KEY_SEGURIDAD;
        me.setState({
            loading: true,
            open: true
        });        
        try{
            await fetch(URL,{
                method:'post',
                mode: 'cors',
                headers: {
                    'Accept':'application/json',
                    'ApiKey': Apikey,
                    'Content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(function(res){
                return res.json();
            }).then(function(response){
                me.setState({
                    loading: false,
                    open: false
                });
                if(response.Code === 0){
                    if(response.Data.Success){
                        var convert = me.parseJwt(response.Data.Token);
                        localStorage.setItem(process.env.REACT_APP_KEY_TOKEN, response.Data.Token);
                        localStorage.setItem(process.env.REACT_APP_KEY_REFRESH_TOKEN, response.Data.RefreshToken);
                        console.log('visualizando token');
                        console.log(response.Data);
                        me.setState({
                            init: true
                        });

                    }else{
                        var message = "";
                        if(response.Data.Errors.length > 0){
                            response.Data.Errors.forEach(element => {
                                message = `${element}.`
                            });
                            me.ShowDialogMessage(message);                        
                        }                  
                    }
                    
                }else if (response.Code === 1){
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        me.showErrorMessage(message);                        
                    }

                }else if (response.Code === 2){
                    
                    var message = "";
                    if(response.Data.Errors.length > 0){
                        response.Data.Errors.forEach(element => {
                            message = `${element.FieldName}. ${element.Message}. `
                        });
                        me.ShowDialogMessage(message);
                    }
                }else if(response.Code === 3){
                    me.ShowDialogMessage(response.Message);
                }

            }).catch((error) =>{
                console.log(error);
            })
        }catch(e){
            console.log(e);
        }
    }

    validateFields = () =>{
        var obj = {
            UserLogin: this.state.userName,
            Password: this.state.password,
            AplicationId : 2
        }   
        if(this.validateInputsEmpty(obj)){
            this.postData(obj)
        }         
        else
        {
            this.ShowDialogMessage('Debe llenar todos los campos.');
        }   
    }

    handleModel = () =>{
        this.setState({
            openPassword: !this.state.openPassword
        })
    }

    validateInputsEmpty = (obj) =>{
        var valid = true;
        if (obj.UserLogin === ""){
            this.setState({userEmpty: true})
            valid = false;
        }
        if(obj.Password === ""){
            this.setState({passEmpty: true})
            valid = false;
        }
        return valid;
    }

    myChangeHandlerName = (e) => {        
        if(e.target.value !== ""){
            this.setState({
                userName: e.target.value.toString(),
                userEmpty: false
            });
        }else{
            this.setState({
                userName: "",
                userEmpty: true
            });
        }      
    }

    myChangeCaptcha = (e) => {
        this.setState({
            captcha: true
        });
    }

    myChangeHandlerPassword = (e) => {
        if(e.target.value !== ""){
            this.setState({
                password: e.target.value.toString(),
                passEmpty: false
            });
        }else{
            this.setState({
                password: "",
                passEmpty: true
            });
        }  
    }

    onChangeCheckedAscertainable = (e) =>{
        console.log(e.target.value)
    }    

    setViewRegister = () =>{
        this.setState({
            setViewRegister: true
        })
    }

    setViewRecovered = () =>{
        this.setState({
            setViewRecover: true
        })
    }

    render(){
        const {loading} = this.state;

        if(this.state.isLoggedIn){
            return <Redirect to="/Main" />
        }

        if(this.state.init){
            return <Redirect to="/Main"/>
        }
        console.log("{process.env.NODE_ENV}");
        console.log(process.env.NODE_ENV);
        
        return(
            <div className="FormLogin">      
                <Grid container>
                    <Grid container /*spacing={3}*/ style={{marginTop: 100}} >
                        <Grid item xs={4}>
                        </Grid>
                        <Grid item md={4} xs={12}>
                            <div className="login-logo">
                                <a>Bello Horizonte Admin</a>
                            </div>
                            <div style={{backgroundColor:'#fff', minWidth:'30%', maxWidth:'80%', margin:'auto', paddingBottom:30,paddingTop:20}}>
                                <Grid item xs={12} style={{padding:12}}>                            
                                    <a>Iniciar Sesión</a>
                                </Grid>
                                <Grid item xs={12} style={{padding:12}}>                            
                                    <TextFieldLogin 
                                        label="Usuario"
                                        type={'text'}
                                        onChange={this.myChangeHandlerName} 
                                        maxLength="25"
                                        className={(this.state.userEmpty? this.state.errorEmpty : '') + " widthImput"}
                                        autoComplete="off"
                                    />
                                </Grid>
                                <Grid item xs={12} style={{padding:12}}>
                                    <TextFieldLogin 
                                        label="Contraseña"
                                        type={this.state.isRevealPassword? 'text' : 'password'}
                                        onChange={this.myChangeHandlerPassword}
                                        className={(this.state.passEmpty? this.state.errorEmpty : '') + " widthImput"}
                                        autoComplete="off"
                                    />                        
                                    <span onClick={this.togglePassword}>
                                        {
                                            this.state.isRevealPassword? (
                                                <EyeIcon className="customIcon" />
                                            ):( <EyeSlashIcon className="customIcon" /> )
                                        }
                                    </span>
                                </Grid>
                                <Grid item xs={12} style={{padding:12, textAlign:'left'}}>
                                    <button type="button" className="btn" onClick={this.handleModel}>Recuperar Contraseña</button>
                                </Grid>
                                <Grid item xs={12} style={{padding:12}}>
                                    <ButtonLogin imgIcon={true} nameAction={'INGRESAR'} handleClick={this.validateFields}/>
                                    <Button 
                                        variant="contained" 
                                        color="primary"
                                        className="widthCustom"
                                        style={{display:'none'}}
                                        onClick={this.validateFields}
                                        disabled={loading}
                                    >
                                        { loading && <span>Cargando ...</span>}
                                        { !loading && <span>ENTRAR</span>} 
                                    </Button>
                                </Grid>                    
                            </div>
                        </Grid>
                        <Grid item xs={4}>
                        </Grid>
                    </Grid>                
                    <Progress open={this.state.open}/>
                    <ShowDialog open={this.state.show} message={this.state.messageError} handleClose={this.handleClose}/>
                    <RecoverPassword open={this.state.openPassword} handleClose={this.handleClosePassword}/>
                </Grid>               
            </div>
        );
    }
}