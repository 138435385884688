import './App.css';
import {BrowserRouter as Router,Switch, Route} from 'react-router-dom';
import LoginApp from './components/login/Login.jsx';
import DrawerNavigation from './components/drawerNavigation/DrawerNavigation'
import '@coreui/coreui/scss/coreui.scss';

function App() {
  return (
    <div className="App">
      <Router>
        <Switch>      
            <Route path={`/`} exact component={LoginApp} />
            <Route path={`/Main`}exact component={DrawerNavigation} />
        </Switch>
      </Router>
    </div>
  );
}

export default App;